import React from "react";
import { Box, Typography, Container, Paper, Grid } from "@mui/material";
import { Link } from "react-router-dom"; // Assuming you're using React Router
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import GavelIcon from "@mui/icons-material/Gavel";
import ReplayIcon from "@mui/icons-material/Replay";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CancelIcon from "@mui/icons-material/Cancel";
import routes from "../utils";

const supportLinks = [
  {
    title: "Terms & Conditions",
    description: "Read our terms of service before making a purchase.",
    icon: <GavelIcon className="text-[#2858a3] text-5xl" />,
    link: routes.terms,
  },
  {
    title: "Policy",
    description: "Check our return and refund process.",
    icon: <ReplayIcon className="text-[#2858a3] text-5xl" />,
    link: routes.policy,

  },
  {
    title: "Order Cancellation Policy",
    description: "Learn how to cancel or modify your order.",
    icon: <CancelIcon className="text-[#2858a3] text-5xl" />,
    link: routes.return_policy,

  },
  {
    title: "Shipping Policy",
    description: "Find details about delivery times and charges.",
    icon: <LocalShippingIcon className="text-[#2858a3] text-5xl" />,
    link: routes.shipping_policy,
  },
];

const CustomerSupport = () => {
  return (
    <Box className="w-full bg-gray-50 min-h-screen">
      <Container maxWidth="lg" className="py-10 px-4 sm:px-6 lg:px-10">
        {/* Customer Support Section */}
        <Paper elevation={3} className="p-6 sm:p-8 rounded-lg shadow-md bg-white">
          <Box className="flex flex-col items-center">
            <SupportAgentIcon className="text-[#2858a3] text-6xl mb-4" />
            <Typography 
              variant="h4" 
              className="font-semibold text-gray-800 mb-2 text-lg sm:text-2xl text-center"
            >
              Customer Support
            </Typography>
            <Typography className="text-gray-600 text-sm sm:text-base text-center max-w-lg">
              Need assistance? We are here to help! Explore the links below for information on policies, returns, shipping, and order cancellation.
            </Typography>
          </Box>

          {/* Support Links */}
          <Grid container spacing={3} className="mt-6">
            {supportLinks.map((item, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Link to={item.link} className="no-underline">
                  <Paper
                    elevation={2}
                    className="p-6 rounded-lg shadow-sm bg-white hover:shadow-md transition duration-300 flex flex-col items-center text-center"
                  >
                    {item.icon}
                    <Typography variant="h6" className="text-gray-800 font-medium mt-3">
                      {item.title}
                    </Typography>
                    <Typography className="text-gray-600 text-sm mt-1">
                      {item.description}
                    </Typography>
                  </Paper>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Container>
    </Box>
  );
};

export default CustomerSupport;
