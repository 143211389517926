import React from "react";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { routes } from "../../data";
import { Brands, Categories, Deals } from "./NavigationDropDown";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";

const NavigationBar = () => {

  return (
    <Box className="h-12  w-full bg-[#2858a3] items-center justify-center gap-x-6 hidden sm:hidden lg:flex">
      {/* Catgories */}
      <Categories />

      {/* <Link to="/category?type=all" key="" className="flex items-center">

        <span className="text-lg text-[#FC4747] -mt-1 pe-1.5">
          <StorefrontOutlinedIcon />
        </span>

        <Typography className="poppins text-sm text-white">
          Shop
        </Typography>
      </Link> */}


      {/* Other Nav */}
      {routes.map((route, i) => {
        switch (route.title) {
          case "Brands":
            return <Brands key={i} title={route.title} />;

          case "Best Deals":
            // return <Deals key={i} title={route.title} />;
            return false;

          default:
            return (
              <Link to={route.link} key={i} className="flex items-center">
                {route?.icon && (
                  <span className="text-lg text-[#FC4747] -mt-1 pe-1.5">
                    {route?.icon}
                  </span>
                )}
                <Typography className="poppins text-sm text-white">
                  {route.title}
                </Typography>
              </Link>
            )
        }
      })}

    </Box>
  );
};

export default NavigationBar;
