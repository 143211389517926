import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Faq = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const faqData = [
    {
      question: "What types of products does Buraq Star offer?",
      answer:
        "Buraq Star offers a wide range of high-quality products under the brands Novex, Zilco, Cavil, and Buraq. We offer electrical supplies, sanitary ware, hand and power tools, hardware, and paint accessories. We provide top-quality products for all your project needs.",
    },
    {
      question: "Are the products available for international shipping?",
      answer:
        "No, we don’t offer international shipping on our products. Please check our shipping policy for more details on delivery times and costs.",
    },
    {
      question: "How do I know if a product is right for me?",
      answer:
        "Each product on our website comes with a detailed description, specifications, and usage instructions to help you make an informed decision. If you have any specific questions, feel free to contact us.",
    },
    {
      question: "Are Buraq Star products covered by a warranty?",
      answer:
        "Yes, all our products come with a manufacturer’s warranty. Warranty details are listed on the product page, and you can also contact us for more information.",
    },
    {
      question: "What payment methods do you accept?",
      answer:
        "We accept a variety of payment methods, including credit/debit cards, PayPal, and other secure payment gateways.",
    },
    {
      question: "How do I track my order?",
      answer:
        "Once your order is shipped, we will send you an email with a tracking number. You can use this number to track your order on our website or through the shipping provider.",
    },
    {
      question: "Can I return or exchange a product?",
      answer:
        "Yes, we offer a return and exchange policy. If you are not satisfied with your product, please review our return policy on the website for instructions.",
    },
    {
      question: "How can I contact customer support?",
      answer:
        "You can reach our customer support team via email, phone, or by filling out the contact form on our website. We aim to respond within 24 hours.",
    },
    {
      question: "Are the products safe to use outdoors?",
      answer:
        "Yes, most of our products, including the solar lights and tools, are designed for outdoor use and are weather-resistant. Please check the individual product specifications for more details on durability and safety.",
    },
    {
      question: "Do you offer installation services for your products?",
      answer:
        "At the moment, we do not offer installation services, but our products come with clear instructions for easy setup. If you need assistance, our customer support is happy to guide you.",
    },
    {
      question: "Can I cancel my order after placing it?",
      answer:
        "If you need to cancel your order, please contact us as soon as possible. Once an order has been processed or shipped, we may not be able to cancel it. Please refer to our cancellation policy for more details.",
    },
    {
      question: "What should I do if my product is damaged or faulty?",
      answer:
        "If you receive a damaged or faulty product, please contact our customer support team within 7 days of delivery. We will assist with returns, exchanges, or replacements as per our policy.",
    },
    {
      question: "Are the products customizable?",
      answer:
        "Currently, we do not offer customization for our products. However, we continually update our inventory, so check back for new product releases.",
    },
  ];

  return (
    <Box className="w-full h-fit mt-10 px-4 sm:px-8 lg:px-16">
      <Typography className="poppins text-3xl font-bold text-[#2E2E2E] uppercase py-4 text-center">
        Frequently Asked Questions
      </Typography>
      {faqData.map((item, index) => (
        <Accordion
          key={index}
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
          className={`my-3 rounded-lg shadow-none border-none ${
            expanded === `panel${index}`
              ? "bg-[#2858a3] text-white"
              : "bg-[#F5F5F5] text-[#2E2E2E]"
          }`}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon className="bg-white rounded-full h-7 w-7 text-[#2858a3]" />
            }
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
          >
            <Typography
              sx={{ width: "100%" }}
              className="poppins text-sm sm:text-base"
            >
              {item.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="bg-white text-xs sm:text-sm poppins text-[#5D5D5D] py-3 px-5">
            {item.answer}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default Faq;
