import React from "react";
import { Box, Typography, Container, Paper } from "@mui/material";
import { Hero } from "../components";
import bg from "../assets/policy_banner.jpg";


const TermsAndConditions = () => {
  const termsSections = [
    {
      title: "General Information",
      description: `Buraq Star Trading Co. LLC offers products through its online store, available at www.buraqstar.com. These Terms and Conditions govern your use of the Site and the purchase of products from us.`,
    },
    {
      title: "Account Registration",
      description: `To make purchases, you may need to create an account. You must provide accurate details and are responsible for maintaining account security. Notify us if you suspect unauthorized use of your account.`,
    },
    {
      title: "Product Information and Availability",
      description: `We strive for accurate descriptions and pricing, but errors may occur. We reserve the right to modify or discontinue products. In cases of pricing errors, we will notify you and offer alternatives or refunds.`,
    },
    {
      title: "Orders and Payment",
      description: `
      - Orders are subject to acceptance.
      - Pricing is listed in AED, BHD, PKR, and QAR, excluding taxes or shipping fees unless otherwise stated.
      - Payment is accepted via VISA and Mastercard credit/debit cards.`,
    },
    {
      title: "Shipping and Delivery",
      description: `We ship locally to Bahrain, Qatar, Pakistan, and the UAE. Shipping fees are calculated at checkout. Estimated delivery times may vary but are not guaranteed.`,
    },
    {
      title: "Cancellation & Returns",
      description: `
      - Cancelled orders before dispatch will be issued a credit note/voucher.
      - Returns require contacting customer service at 800 66839.
      - Approved returns will be refunded as a credit note or voucher.`,
    },
    {
      title: "User Conduct",
      description: `Users must not engage in unlawful activities, transmit harmful content, or disrupt site functionality.`,
    },
    {
      title: "Intellectual Property",
      description: `All site content is owned by Buraq Star Trading Co. LLC and protected by intellectual property laws.`,
    },
    {
      title: "Limitation of Liability",
      description: `We are not liable for indirect, incidental, or consequential damages related to site use or product purchases. Our total liability is limited to the amount paid for the product.`,
    },
    {
      title: "Privacy and Data Protection",
      description: `We respect your privacy. Refer to our Privacy Policy for details on how we collect and protect your personal data.`,
    },
    {
      title: "Modifications",
      description: `We may update these Terms and Conditions at any time. Continued use of the Site indicates acceptance of changes.`,
    },
    {
      title: "Governing Law and Dispute Resolution",
      description: `These Terms and Conditions are governed by UAE laws.`,
    },
    {
      title: "Contact Us",
      description: `Buraq Star Trading Co. LLC\nPhone: 800 66839\nEmail: ecommerce@buraqstar.com`,
    },
  ];

  return (
    <Box className="w-full bg-gray-50 min-h-screen">
      <Hero bg={bg} title="Terms and Conditions" />
      <Container maxWidth="lg" className="py-10 px-4 sm:px-6 lg:px-10">
        <Paper elevation={3} className="p-6 sm:p-8 rounded-lg shadow-md bg-white">
          <Typography 
            variant="h4" 
            className="font-semibold text-gray-800 mb-4 text-center text-lg sm:text-2xl"
          >
            Terms and Conditions
          </Typography>
          <Typography 
            variant="subtitle1" 
            className="text-gray-600 text-center mb-6 text-sm sm:text-base"
          >
            Effective Date: 2-4-2025
          </Typography>
          <Typography className="text-gray-700 leading-relaxed mb-6 text-sm sm:text-base">
            Welcome to Buraq Star Trading Co. LLC! By using our site, you agree to comply with these Terms and Conditions.
          </Typography>
          {termsSections.map((section, i) => (
            <Box key={i} className="mb-6">
              <Typography 
                variant="h6" 
                className="text-gray-800 font-bold mb-2 text-base sm:text-lg"
              >
                {i + 1}. {section.title}
              </Typography>
              <Typography className="text-gray-700 leading-relaxed text-sm sm:text-base whitespace-pre-line">
                {section.description}
              </Typography>
            </Box>
          ))}
        </Paper>
      </Container>
    </Box>
  );
};

export default TermsAndConditions;
