import React from "react";
import { Box } from "@mui/material";
import bg from "../assets/policy_banner.jpg";
import { Hero, Faq } from "../components";

const FAQ = () => {
  return (
    <Box className="w-full h-auto bg-white m-0 p-0">
      <Hero bg={bg} title={"FAQ"} />
      <Box className="w-full h-fit px-5 md:px-10 sm:px-16 pt-10 pb-16">
        <Faq />
      </Box>
    </Box>
  );
};

export default FAQ;
