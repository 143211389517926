// Correct HomeSlider.js
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import { EffectFade, Pagination, Autoplay } from "swiper/modules";
import { Box, Button, Typography } from "@mui/material";
import slide1 from "../../assets/slide1.png";
import slide2 from "../../assets/slide2.png";
import slide3 from "../../assets/slide3.png";
import slide4 from "../../assets/slide4.png";

const HomeSlider = () => {
  return (
    <Swiper
      spaceBetween={30}
      effect="fade"
      pagination={{ clickable: true }}
      centeredSlides={true}
      autoplay={{ delay: 2500, disableOnInteraction: false }}
      modules={[Autoplay, EffectFade, Pagination]}
      className="slider"
      style={{
        "--swiper-pagination-color": "#ffffff",
        "--swiper-pagination-bullet-inactive-color": "transparent",
        "--swiper-pagination-bullet-inactive-opacity": "1",
        "--swiper-pagination-bullet-size": "16px",
        "--swiper-pagination-bullet-horizontal-gap": "6px",
      }}
    >
      {[slide1, slide2, slide3, slide4].map((slide, index) => (
        <SwiperSlide
          key={index}
          className="bg-cover bg-no-repeat bg-center"
          style={{ backgroundImage: `url(${slide})` }}
        >
          <Box className="flex flex-col justify-center items-center text-white h-full w-full gap-y-4">
            <Typography className="text-lg font-semibold poppins uppercase">
              Hot Products
            </Typography>
            <Typography className="text-2xl sm:text-5xl w-full sm:w-[60%] text-center font-semibold poppins uppercase">
              Get Your <span className="text-5xl sm:text-7xl">Equipments</span> Online
            </Typography>
            <Typography className="text-lg poppins uppercase">
              Start From <span className="font-bold">28 AED</span>
            </Typography>
            <Button className="text-white rounded-lg bg-[#FF3030] uppercase poppins py-2.5 px-5 ms-2 text-xs">
              Shop Now
            </Button>
          </Box>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default React.memo(HomeSlider); // Use React.memo, but NOT React.lazy




