import { Box, Fade, Grid } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import "../components/Checkout/Checkout.css";
import CartReview from "../components/Checkout/CartReview";
import OrderSummary from "../components/Checkout/OrderSummary";
import CheckoutForm from "../components/Checkout/CheckoutForm";
// import DrawerContent from '../components/Cart/DrawerContent';
import PaymentMethods from "../components/Checkout/PaymentMethods";
import { AuthContext } from "../AuthContext";
import axios from "axios";
import { BASE_URL } from "../constants";
import MiniLoginForm from "../components/Checkout/MiniLoginForm";
import DeliveryAddress from "../components/Checkout/DeliveryAddress";
import CreditCardForm from '../components/Checkout/CreditCardForm';

const Checkout = () => {
  const { isLoggedIn } = useContext(AuthContext)
  const [addressData, setAddressData] = useState([]);

  const [formData, setFormData] = useState({
    fullName: "",
    lastName: "",
    email: "",
    password: "",
    phone: "",
    delivery_address: "",
    country: "",
    state: "",
    city: "",
    zip_code: "",
    cardName:"",
    cardExpirationDate:"",
    cardCvv:"",
    cardNumber:"",
  });
  const [errors, setErrors] = useState({});
  const [selectedAddress, setSelectedAddress] = useState(null);

  const handleSelectAddress = async (addressId) => {
    setSelectedAddress(addressId);
    const response = await axios.post(`${BASE_URL}/api/v1/checkout/get-shipping-cost/${addressId}`, {
      headers: {
        Authorization: `Bearer ${localStorage?.getItem('token')}`
      },
    });
    setShippingCost(response.data.standard_delivery_cost)
  };
  // for notification
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  // loading
  const [loading, setLoading] = useState(false);
  const [paymentType, setPaymentType] = useState('cash_on_delivery');
  const [shippingCost, setShippingCost] = useState(0);


  return (
    <>
      <Grid className="w-full h-fit py-10 px-10 sm:px-16 flex flex-col sm:flex-row gap-[16px]">
        <Box className="w-[30%] hidden lg:block">
          {!isLoggedIn ? <MiniLoginForm /> : null}
          <CartReview />
        </Box>

        <Box className="w-full sm:w-[60%] lg:w-[40%]">
          {isLoggedIn
            ? <DeliveryAddress
              selectedAddress={selectedAddress}
              onSelect={handleSelectAddress}
              addressData={addressData}
              setAddressData={setAddressData} />
            : <CheckoutForm
              formData={formData}
              setFormData={setFormData}
              errors={errors}
              setErrors={setErrors}
              setShippingCost={setShippingCost}
            />}
        </Box>
        <Box className="w-full sm:w-[40%] lg:w-[30%]">
          <PaymentMethods paymentType={paymentType} setPaymentType={setPaymentType} />
          {/* {
            paymentType == 'network_payment' ?
              <Fade in={true}>
                <div>
                  <CreditCardForm formData={formData}  setFormData={setFormData} />
                </div>
              </Fade>
              :
              null
          } */}
          <OrderSummary
            loading={loading}
            setLoading={setLoading}
            formData={formData}
            paymentType={paymentType}
            errors={errors}
            setErrors={setErrors}
            open={open}
            setOpen={setOpen}
            message={message}
            shippingCost={shippingCost}
            setMessage={setMessage}
            selectedAddress={selectedAddress}
          />
        </Box>
      </Grid>
    </>
  );
};

export default Checkout;
