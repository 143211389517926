import React, { useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { getBrandLogo } from "../../utils";

const Description = ({ description, specifications, brand, datasheet }) => {

  const [mode, setMode] = useState(1);
  
  

  const handleView = () => {
    if (datasheet) {
      window.open(datasheet, '_blank');
    } else {
      alert('No datasheet available to view.');
    }
  };

  return (
    <>
      <Grid item xs={12} className="mt-10">
        <Box className="w-full h-fit">
          <Box className="flex gap-x-3">
            <Button
              onClick={() => setMode(1)}
              className={`${mode === 1 ? 'bg-black' : 'bg-[#1E55AC]'} py-4 px-10 text-lg text-white font-semibold poppins capitalize`}
            >
              Overview
            </Button>
            <Button
              onClick={() => setMode(2)}
              className={`${mode === 2 ? 'bg-black' : 'bg-[#1E55AC]'} py-4 px-10 text-lg text-white font-semibold poppins capitalize`}
            >
              Specifications
            </Button>
          </Box>
        </Box>
      </Grid>
      {mode === 1 ? (
        <Grid item xs={12} sm={7} className="mt-10">
          <Typography className="poppins text-xs leading-6 text-[#5D5D5D] py-1.5">
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </Typography>
          <img className="h-10 w-auto my-6" src={getBrandLogo(brand)} alt={brand} />
          <Box className="flex items-center gap-x-4 mt-10">
            <Typography className="poppins text-base font-semibold text-[#2E2E2E] capitalize py-1.5">
              Data Sheet Option
            </Typography>
            {datasheet ? (
              <>
                <Button
                  variant="outlined"
                  onClick={handleView}
                  className="border-[1.5px] font-semibold border-black py-3 px-8 capitalize text-black poppins text-base"
                >
                  View
                </Button>
                {/* <Button
                  variant="outlined"
                  onClick={handleDownload}
                  className="border-[1.5px] font-semibold border-[#1E55AC] py-3 px-8 capitalize text-[#1E55AC] poppins text-base"
                >
                  Download
                </Button> */}
              </>
            ) : (
              <Typography className="poppins text-base text-[#FF0000] capitalize py-1.5">
                No datasheet available
              </Typography>
            )}
          </Box>
        </Grid>
      ) : (
        <>
          {/* <Grid item xs={12} sm={7} className="mt-10 hidden sm:block" /> */}
          <Grid item xs={12} sm={5} className="mt-5">
            <Box className="mt-6">
              {specifications.map((feat, i) => (
                <Box className="flex gap-x-6 my-2" key={i}>
                  <Typography className="poppins text-xs w-[30%] uppercase">
                    {feat.specification}
                  </Typography>
                  <Typography className="poppins text-xs text-[#5D5D5D] uppercase">
                    {feat.value}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Grid>
        </>
      )}
    </>
  );
};

export default Description;
