import React, { useEffect, useState } from "react";
import { Box, Divider, Typography } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import axios from 'axios';

const className = "text-sm leading-6 poppins text-[#5D5D5D] mt-3";
const accessToken = 'IGAAZAHBPYTBB1BZAE0zODVqS0dXQ3NfOGU4cEFfRU1JNmE1d3g1NWFjLUxfVVdxX0N1OUY1ME1fS0pEaktJb21Vd2FGMDNDWXFNYWNWaUJXVHExWDZAyMFpCd1B3a0ZAkdnd6cjZAfeEZAqbnJ1aW02WU1pbHRhS24tS0hWS0pWOVVNOAZDZD';

const SEOInstagram = () => {

  const [followers, setFollowers] = useState(0);

  useEffect(() => {
    const fetchInstagramData = async () => {
      // const accessToken = 'IGQWRNRHhjUU1kY0FFM2VBT1BqTC1qQXNXbGhtX1BFRWx2VlU4QUFJVWlRNTFjNUV2UWFWM21abzlBV3QxcFcwY2ZATeEIyMDRWYkFfeTFhMmUzcE5WaUw3YlQxajNKVUtDUUJUVjBIY2JRQjlIdFJSUTBrYmRsUzgZD';

      try {
        const userResponse = await axios.get(`https://graph.instagram.com/me?fields=followers_count&access_token=${accessToken}`);
        setFollowers(userResponse.data.followers_count);
      } catch (error) {
        console.error('Error fetching Instagram user data', error);
      }
    };

    fetchInstagramData();
  }, []);

  
  return (
    <>
      <Box className="w-full h-fit flex flex-col items-center">
        <Box className="w-full h-fit py-10 px-8 sm:px-28">
          <Typography className="text-xl pb-4 poppins text-[#141516] uppercase font-semibold">
            {/* SEO Description */}
            Your One-Stop Shop for Quality Building, Electrical, and Sanitary Supplies!
          </Typography>

          <Box style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
            <Typography className={className}>
              In the ever-evolving world of construction and maintenance, having access to top-notch materials is essential. At Buraq Star Trading, we understand the significance of reliable supplies for your projects, whether you're constructing a commercial building, renovating a residential space, or simply upgrading your home. That's why we've made it our mission to provide you with everything you need under one roof.
            </Typography>

            <Typography className={className}>
              Quality is our cornerstone. We believe that the success of any project hinges on the materials used, and that's why we source only the best. From robust building materials to cutting-edge electrical components and premium sanitary supplies, we have it all. Our extensive selection ensures that you can find everything required to complete your project to the highest standards.
            </Typography>

            <Typography className={className}>
              When it comes to building supplies, we've got you covered. Whether you're laying foundations, erecting walls, or adding the finishing touches, you can trust in the quality and durability of our products. We work closely with reputable suppliers to bring you materials that meet industry standards and exceed your expectations.
            </Typography>

            <Typography className={className}>
              Electrical work requires precision and reliability, and that's exactly what you'll find in our electrical supplies. From wiring and cables to switches and outlets, we offer a comprehensive selection of electrical components to suit your needs. Safety is paramount, which is why we stock products that are rigorously tested and certified. With our electrical supplies, you can ensure that your projects are not only functional but also secure.
            </Typography>

            <Typography className={className}>
              In the realm of sanitation, hygiene and quality go hand in hand. Our sanitary supplies are designed to meet the highest standards of cleanliness and durability. From faucets and sinks to toilets and showers, we provide a wide array of options to suit every budget and style. We understand the importance of efficiency in sanitation, which is why our products are not only easy to install but also simple to maintain.
            </Typography>

            <Typography className={className}>
              At Buraq Star Trading, convenience is key. We know that time is of the essence in any project, which is why we strive to make your shopping experience as seamless as possible. Our user-friendly website allows you to browse our extensive catalog from the comfort of your home or office. With just a few clicks, you can find the products you need and have them delivered right to your doorstep.
            </Typography>

            <Typography className={className}>
              But our commitment to excellence doesn't stop there. We pride ourselves on providing exceptional customer service every step of the way. Our knowledgeable staff are here to assist you with any questions or concerns you may have, ensuring that you have the information you need to make informed decisions.
            </Typography>

            <Typography className={className}>
              Whether you're a seasoned contractor, a DIY enthusiast, or a homeowner embarking on a renovation project, Buraq Star Trading is your ultimate destination for quality building, electrical, and sanitary supplies. With our extensive selection, superior quality, and outstanding service, we're here to help you bring your projects to life. Shop with us today and experience the difference for yourself!
            </Typography>
          </Box>

        </Box>
        <Divider className="bg-[#CCCCCC] my-4 w-[90%]" />
        <Box className="flex justify-between px-8 sm:px-28 w-full pt-16 pb-4">
          <Typography className="text-3xl pb-4 poppins text-[#141516] capitalize font-semibold">
            <InstagramIcon className="text-4xl" /> Instagram Feed
          </Typography>
          <Box className="flex items-center gap-x-3">
            <Typography className="text-lg poppins text-[#02ADEC] ">
              <PersonOutlineOutlinedIcon />
             {followers}
            </Typography>
            <Typography className="text-lg poppins text-[#02ADEC] ">
              <FavoriteBorderOutlinedIcon />
              5k
            </Typography>
            <Typography className="text-lg poppins text-[#02ADEC] ">
              <QuestionAnswerOutlinedIcon />
              5k
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className="w-full h-72">
        <Slider />
      </Box>
    </>
  );
};

export default SEOInstagram;

const Slider = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchInstagramImages = async () => {
      try {
        const response = await axios.get(`https://graph.instagram.com/me/media?fields=id,caption,media_url,permalink&access_token=${accessToken}`);
        setImages(response.data.data);
      } catch (error) {
        console.error('Error fetching Instagram images', error);
      }
    };

    fetchInstagramImages();
  }, [accessToken]);

  return (
    <Swiper
      breakpoints={{
        0: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        480: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
      }}
      autoplay={{
        delay: 2000,
        disableOnInteraction: false,
      }}
      className="h-full w-full pb-6 mb-10"
      modules={[Autoplay]}
    >
      {images.map((image) => (
        <SwiperSlide
        className="rounded-md bg-cover bg-no-repeat bg-center group transition"
        style={{ backgroundImage: `url(${image.media_url})` }}
        key={image.id}
      >
        <a href={image.permalink} target="_blank" rel="noopener noreferrer">
          <Box className="w-full hidden group-hover:flex cursor-pointer h-full rounded-md sale-gradient justify-center flex-col gap-y-1 items-center">
            <InstagramIcon className="text-6xl text-white" />
            <Typography className="text-base text-white">{image.caption}</Typography>
          </Box>
        </a>
      </SwiperSlide>
      ))}
    </Swiper>
  );
};
