import React, { useState, useEffect } from 'react';
import logo from '../assets/footer_logo.svg';

const ComingSoon = () => {
  const TARGET_DATE = '2025-03-15T00:00:00';
  const targetDate = new Date(TARGET_DATE);

  const calculateTimeLeft = () => {
    const now = new Date();
    const difference = targetDate - now;
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / (1000 * 60)) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  const timerComponents = Object.keys(timeLeft).map((interval) => (
    <div key={interval} className="flex flex-col items-center mx-2 sm:mx-4">
      <span className="text-4xl sm:text-5xl md:text-6xl font-bold text-[#2858a3]">
        {timeLeft[interval]}
      </span>
      <span className="text-sm sm:text-base md:text-lg uppercase text-gray-600">
        {interval}
      </span>
    </div>
  ));

  const handleSubscribe = () => {
    const email = window.prompt("Enter your email to subscribe for updates:");
    if (email) {
      if (email === "test@gmail.com") {
        localStorage.setItem("test_mode", 'true');
        window.alert("Refresh the page to begin test mode");
      } else {

        window.alert("Thank you for subscribing to our notifications!");
      }
    }
  };

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gradient-to-r from-[#2858a3] to-indigo-600 p-6 sm:p-8">
      {/* Logo */}
      <img src={logo} alt="Logo" className="w-28 sm:w-36 md:w-44 h-auto mb-6 sm:mb-8" />

      {/* Main Heading */}
      <h1 className="text-3xl sm:text-5xl md:text-6xl font-extrabold text-white mb-3 sm:mb-5 text-center">
        Coming Soon
      </h1>

      {/* Subheading */}
      <p className="text-sm sm:text-lg md:text-xl text-white mb-6 sm:mb-8 text-center px-4 max-w-lg">
        Our website is under construction. Stay tuned for an amazing experience!
      </p>

      {/* Countdown Timer */}
      <div className="bg-white p-4 sm:p-6 md:p-8 rounded-lg shadow-lg flex flex-wrap justify-center gap-4 w-full max-w-md sm:max-w-lg md:max-w-xl">
        {timerComponents.length ? timerComponents : <span>Time's up!</span>}
      </div>

      {/* Subscribe Button */}
      <button
        onClick={handleSubscribe}
        className="mt-6 sm:mt-8 px-5 sm:px-6 py-2 bg-white text-[#2858a3] rounded-md hover:text-white hover:bg-[#2858a3] transition duration-300 text-sm sm:text-lg md:text-xl"
      >
        Subscribe
      </button>
    </div>
  );
};

export default ComingSoon;
