import React, { useEffect, useState, useRef } from "react";
import { Grid } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export default function ProductSlider({ images }) {
  const [sliderHeight, setSliderHeight] = useState("50vh");
  const swiperRef = useRef(null);

  useEffect(() => {
    const updateHeight = () => {
      const viewportHeight = window.innerHeight;
      setSliderHeight(viewportHeight > 768 ? "70vh" : "50vh");
    };

    window.addEventListener("resize", updateHeight);
    updateHeight(); // Initial call to set height

    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      const swiperInstance = swiperRef.current.swiper;

      // Manually assign the navigation elements
      swiperInstance.params.navigation.prevEl = ".swiper-button-prev";
      swiperInstance.params.navigation.nextEl = ".swiper-button-next";
      swiperInstance.navigation.init();
      swiperInstance.navigation.update();
    }
  }, [images]);

  return (
    <div className="relative w-full">
      <Swiper
        ref={swiperRef}
        modules={[Navigation]}
        className="w-full h-full popularSlider"
        style={{ height: sliderHeight }}
      >
        {images?.map((image, i) => (
          <SwiperSlide
            key={i}
            className="bg-contain bg-no-repeat bg-center w-full"
            style={{
              backgroundImage: `url(${image})`,
              height: sliderHeight,
            }}
          />
        ))}

        {/* Custom Navigation Buttons */}
        {images?.length > 1 && (
          <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2 z-10 flex space-x-4">
            {/* Swiper Previous Button */}
            <div className="swiper-button-next bg-black w-10 h-10 rounded-full flex items-center justify-center order-2">
              <ChevronRightIcon className="text-white text-lg" />
            </div>

            {/* Swiper Next Button */}
            <div className="swiper-button-prev bg-black w-10 h-10 rounded-full flex items-center justify-center order-1">
              <ChevronLeftIcon className="text-white text-lg" />
            </div>
          </div>
        )}
      </Swiper>

      {/* Thumbnail Images Positioned Below the Slider */}
      {images?.length > 1 && (
        <Grid
          container
          spacing={2}
          className="mt-4 px-4 sm:px-8"
          sx={{
            width: "100%",
            justifyContent: "center",
          }}
        >
          {images.map((image, i) => (
            <Grid item xs={3} sm={2} key={i} className="cursor-pointer">
              <img
                src={image}
                loading="lazy"
                alt={`Product${i}`}
                className="w-full h-auto p-2 hover:opacity-75 transition duration-300"
                onClick={() => swiperRef.current?.swiper?.slideTo(i)}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </div>
  );
}
