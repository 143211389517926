import { Box } from "@mui/material";
import React from "react";

import NavigationBar from "./NavigationBar";
import SocialLinkBar from "./SocialLinkBar";
import HeaderBar from "./HeaderBar";

const Header = () => {
  return (
    <Box className="w-full">
      <SocialLinkBar />
        <HeaderBar />
      <NavigationBar />
    </Box>
  );
};

export default Header;
