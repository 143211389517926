import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Dropdown from 'react-multilevel-dropdown';
import ajaxService from "../../../services/ajax-service";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const Deals = ({ title }) => {

    const [dealsOpen, setDealsOpen] = useState(false);
    const [deals, setDeals] = useState([])

    const loadDeals = async () => {
        const { success, data } = await ajaxService.get('/all-deals');
        if (success) {
            setDeals(data);
        }
    }

    useEffect(() => {
        loadDeals();
    }, [])

    return (
        <>
            {title}
            <ArrowRightIcon className="text-sm text-[#2858a3]" />

            <Dropdown.Submenu position="right" className={'border-b-[3px] border-[#2858a3] rounded-b-md bg-[#f1f1f1]'} style={{ width: '107%' }}>
                {deals.map((deals, i) => (
                    <Dropdown.Item key={i} className={`w-full flex items-center justify-between gap-x-14`}>
                        <Link
                            to={`/deals?type=${deals.slug}`}
                            className={`w-full flex items-center justify-between gap-x-14`}
                        >
                            {deals.name}
                        </Link>
                    </Dropdown.Item>
                ))}
            </Dropdown.Submenu>
        </>
    )
}

export default Deals


{/* <Dropdown
position="right"
title={<>{title} {dealsOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}</>}
className="poppins text-sm text-[#2858a3] flex items-center relative"
onClick={() => setDealsOpen(!dealsOpen)}
menuClassName="border-b-[3px] border-[#2858a3] rounded-b-md bg-[#f1f1f1] text-black h-fit absolute z-50 top-9 left-0 block"
>
{deals.map((val, i) => (
    <Dropdown.Item key={i} className={`w-full flex items-center justify-between gap-x-14`}>
        <Link
            to={`/deals?type=${val.slug}`}
            className={`w-full flex items-center justify-between gap-x-14`}
        >
            {val.name}
        </Link>
    </Dropdown.Item>
))}
</Dropdown> */}