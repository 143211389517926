import React, { useState, useEffect } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import {
  Container,
  Box,
  Skeleton,
  Alert,
  Typography,
  Button,
  List,
  ListItem,
  Divider,
  Paper,
} from "@mui/material";
import { GoogleMapStyle, REACT_APP_GOOGLE_MAPS_API_KEY } from "../constants";
import ajaxService from "../services/ajax-service";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { PickupLocationIcon } from "../constants/googlemapstyle";

const mapStyles = {
  height: "70vh", // Adjust map height
  width: "100%",
};

const defaultCenter = {
  lat: 25.276987, // Dubai Default Latitude
  lng: 55.296249, // Dubai Default Longitude
};

const StoreLocator = () => {
  const [selectedStore, setSelectedStore] = useState(null);
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    ajaxService
      .get("/all-stores")
      .then((response) => {
        if (response.data) {
          setStores(response.data);
        }
        setLoading(false);
      })
      .catch(() => {
        setError("Error fetching store data");
        setLoading(false);
      });
  }, []);

  const handleMarkerClick = (store) => {
    setSelectedStore(store);
  };

  const handleStoreClick = (store) => {
    setSelectedStore(store);
  };

  if (loading) {
    return (
      <Container>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          flexDirection="column"
        >
          <Skeleton variant="rectangular" width="80%" height={400} />
          <Skeleton variant="rectangular" width="80%" height={60} style={{ marginTop: "20px" }} />
          <Skeleton variant="rectangular" width="80%" height={60} style={{ marginTop: "10px" }} />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Alert severity="error" className="poppins">
          {error}
        </Alert>
      </Container>
    );
  }

  return (
    <Box sx={{ bgcolor: "#f7f9fc", pb: 5 }}>
      {/* Header Banner */}
      <Box
        sx={{
          bgcolor: "linear-gradient(90deg, #2858A3 0%, #2E7DCA 100%)",
          color: "white",
          py: 5,
          textAlign: "center",
        }}
      >
        <Typography variant="h4" className="poppins font-bold text-[#2858A3]">
          Store Locator
        </Typography>
        <Typography variant="body1" className="poppins text-[#2858A3]">
          Find our nearest stores and visit us today!
        </Typography>
      </Box>

      <Container>
        <Box display="flex" flexDirection={{ xs: "column", md: "row" }} gap={3} mt={3}>
          {/* Sidebar: Store List */}
          <Paper
            elevation={3}
            sx={{
              flex: 1,
              maxHeight: "70vh",
              overflow: "auto",
              p: 2,
              borderRadius: 2,
            }}
          >
            <Typography variant="h6" className="poppins font-semibold" gutterBottom>
              Available Stores
            </Typography>
            <Divider />
            <List>
              {stores.map((store, index) => (
                <Box key={index} sx={{ mt: 1 }}>
                  <ListItem
                    button
                    onClick={() => handleStoreClick(store)}
                    sx={{
                      bgcolor: selectedStore?.name === store.name ? "#2858A3" : "transparent",
                      color: selectedStore?.name === store.name ? "white" : "black",
                      borderRadius: 1,
                      transition: "0.3s",
                      "&:hover": {
                        bgcolor: "#2858A3",
                        color: "white",
                      },
                    }}
                  >
                    <Box display="flex" alignItems="center">
                      <LocationOnIcon fontSize="small" sx={{ mr: 2 }} />
                      <Box className="hover:text-white">
                        <Typography className="poppins font-medium">{store.name}</Typography>
                        <Typography className="poppins text-sm ">
                          {store.city}
                        </Typography>
                      </Box>
                    </Box>
                  </ListItem>
                  <Divider />
                </Box>
              ))}
            </List>
          </Paper>

          {/* Main Content: Google Map */}
          <Box
            flex={2}
            height="70vh"
            sx={{
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: 2,
              overflow: "hidden",
            }}
          >
            <LoadScript googleMapsApiKey={REACT_APP_GOOGLE_MAPS_API_KEY}>
              <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={selectedStore ? 15 : 11}
                center={
                  selectedStore
                    ? { lat: parseFloat(selectedStore.lat), lng: parseFloat(selectedStore.lng) }
                    : defaultCenter
                }
                options={{
                  styles: GoogleMapStyle,
                  disableDefaultUI: true, // Hide default controls for a cleaner look
                  zoomControl: true, // Keep zoom controls
                }}
              >
                {stores.map((store, index) => (
                  <Marker
                    key={index}
                    position={{ lat: parseFloat(store.lat), lng: parseFloat(store.lng) }}
                    onClick={() => handleMarkerClick(store)}
                    icon={{
                      url: PickupLocationIcon,
                    }}
                  />
                ))}
              </GoogleMap>
            </LoadScript>
          </Box>
        </Box>

        {/* Store Details */}
        {selectedStore && (
          <Box
            mt={4}
            p={3}
            boxShadow={3}
            borderRadius={2}
            bgcolor="white"
            sx={{
              textAlign: "center",
            }}
          >
            <Typography variant="h5" className="poppins font-bold text-black">
              {selectedStore.name}
            </Typography>
            <Typography variant="body1" className="poppins text-gray-700">
              Latitude: {selectedStore.lat}
            </Typography>
            <Typography variant="body1" className="poppins text-gray-700">
              Longitude: {selectedStore.lng}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              href={`https://www.google.com/maps?q=${selectedStore.lat},${selectedStore.lng}`}
              target="_blank"
              sx={{ mt: 2, textTransform: "capitalize", bgcolor: "#2858A3", "&:hover": { bgcolor: "#1e477d" } }}
              className="poppins"
            >
              View on Google Maps
            </Button>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default StoreLocator;
