import React from "react";
import { Box, Typography, Container, Paper } from "@mui/material";
import { Hero } from "../components";
import bg from "../assets/policy_banner.jpg";

const CancellationReturnPolicy = () => {
  const policySections = [
    {
      title: "Order Cancellation Before Dispatch",
      description: `- Customers may cancel their order any time before it is dispatched.
      - To cancel your order, please contact our customer service team immediately through email or phone, providing your order number.`,
    },
    {
      title: "Cancellation After Dispatch",
      description: `- Once an order has been dispatched, it cannot be canceled.
      - However, you may initiate a return or refund as per our return policy.`,
    },
    {
      title: "Non-Cancellable Orders",
      description: `- Certain items may be non-cancellable due to their nature.
      - These will be clearly specified on the product page at the time of purchase.`,
    },
    {
      title: "Refunds for Canceled Orders",
      description: `- If you cancel your order before dispatch, a credit note or voucher of equivalent value will be issued.
      - This credit note or voucher can be used for future purchases on our platform.
      - The credit note or voucher will be processed and sent within 14 business days.`,
    },
    {
      title: "How to Cancel an Order",
      description: `- Contact our customer service team at **info@buraqstar.com** or **800 66839**.
      - Provide your order number and reason for cancellation (if applicable).
      - Ensure the goods are returned in their original condition (if already received).`,
    },
    {
      title: "Exceptions to Cancellation",
      description: `- We reserve the right to refuse cancellation or returns if the item is not returned in its original condition, is damaged, or has missing parts.`,
    },
    {
      title: "Return Window",
      description: `- Customers have **7 days** from the date of delivery to return an item.
      - Returns initiated beyond the **7-day window** will not be accepted.`,
    },
    {
      title: "Condition of Returned Items",
      description: `- The item must be in its **original, unused condition**, with all tags, packaging, and accessories intact.
      - Items that are **damaged, altered, used, or missing parts** will not be eligible for return.`,
    },
    {
      title: "Non-Returnable Items",
      description: `- Certain items, such as **personalized or hygiene-related products**, may not be eligible for return.
      - These exclusions will be clearly mentioned on the product page.`,
    },
    {
      title: "Refund Policy",
      description: `- Once the returned item is **received and inspected**, we will issue a **credit note or voucher** of the same value as the returned product.
      - The credit note or voucher can be used for future purchases on our platform.
      - **No cash refunds** will be provided.`,
    },
    {
      title: "Return Process",
      description: `- Contact our customer service team within **7 days** of receiving your order to initiate a return.
      - Provide your order number and reason for return.
      - Return instructions, including the address for shipping the item back, will be provided upon approval of your request.`,
    },
    {
      title: "Return Shipping Costs",
      description: `- The customer is responsible for the **shipping costs** associated with returning the item.
      - If the return is due to an **error on our part** (e.g., incorrect or defective item), the shipping cost will be covered by us.`,
    },
    {
      title: "Inspection and Approval",
      description: `- All returns will be inspected upon arrival to ensure they meet the conditions outlined above.
      - If the return is **approved**, the **credit note or voucher** will be processed within **15 business days**.`,
    },
    {
      title: "Changes to the Policy",
      description: `- This policy is subject to change. Any updates will be posted on our website.`,
    },
    {
      title: "Contact Us",
      description: `For any questions or concerns regarding this policy, please contact us:\n
      **Buraq Star Trading Co. LLC**\n
      **Phone:** 800 66839\n
      **Email:** info@buraqstar.com`,
    },
  ];

  return (
    <Box className="w-full bg-gray-50 min-h-screen">
      <Hero bg={bg} title="Order Cancellation & Return Policy" />
      <Container maxWidth="lg" className="py-10 px-4 sm:px-6 lg:px-10">
        <Paper elevation={3} className="p-6 sm:p-8 rounded-lg shadow-md bg-white">
          <Typography 
            variant="h4" 
            className="font-semibold text-gray-800 mb-4 text-center text-lg sm:text-2xl"
          >
            Order Cancellation & Return Policy
          </Typography>
          <Typography className="text-gray-700 leading-relaxed mb-6 text-sm sm:text-base">
            At Buraq Star, we strive to ensure a seamless shopping experience. Below is our **Order Cancellation & Return Policy** in accordance with standard customer online rights.
          </Typography>
          {policySections.map((section, i) => (
            <Box key={i} className="mb-6">
              <Typography 
                variant="h6" 
                className="text-gray-800 font-bold mb-2 text-base sm:text-lg"
              >
                {i + 1}. {section.title}
              </Typography>
              <Typography className="text-gray-700 leading-relaxed text-sm sm:text-base whitespace-pre-line">
                {section.description}
              </Typography>
            </Box>
          ))}
        </Paper>
      </Container>
    </Box>
  );
};

export default CancellationReturnPolicy;
