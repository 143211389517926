import React, { useContext, useState, useEffect } from "react";
import { Box, Fab, Drawer } from "@mui/material";
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import { AuthContext } from "../../AuthContext";
import DrawerContent from "./DrawerContent";
import axios from "axios";
import { BASE_URL } from "../../constants";
import { useCart } from "react-use-cart";

const FloatingCart = () => {

    const { isLoggedIn, CartId } = useContext(AuthContext);
    const [items, setItems] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [CartTotal, setCartTotal] = useState(0);
    const { totalUniqueItems,cartTotal } = useCart();
    
    const [open, setOpen] = useState(false);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setOpen(open);
    };

    // useEffect(()=> {
    //     axios.post(`${BASE_URL}/api/v1/carts`, {
    //         cart_id: CartId,
    //         type: ''
    //     },{
    //         headers: {
    //             Authorization: `Bearer ${localStorage?.getItem('token')}`
    //         },
    //     })
    //     .then((res) => {
    //         const products = res?.data?.cart_items.map((item) => {
    //             return {
    //                 cart_id: item?.id,
    //                 product: item?.product,
    //                 quantity: item?.quantity
    //             };
    //         });
    //         setItems(products);
    //         setTotalItems(products?.length)
    //         CalcTotal(products)
    //     })
    //     .catch((error) => {
    //       console.error("Error:", error);
    //     });
    // }, [CartId])

    const CalcTotal = (items) => {
        const total = items?.reduce((acc, item) => {
            const quantity = Number(item.quantity);
            const price = item?.product?.price || 0;
            return acc + (quantity * price);
        }, 0);
        setCartTotal(total)
    }


    const FloatingButton = (
        <Fab
            variant="extended"
            color="primary"
            onClick={() => setOpen(true)}
            sx={{
                padding: '1px',
                position: 'fixed',
                bottom: '50%',
                right: 0,
                background: '#2858a3',
                borderRadius: '10px 0px 0px 10px',
                height: '90px',
                width: '120px',
                color: '#fff'
            }}
        >
            <Box>
                <p style={{ fontSize: '.75rem' }}><ShoppingCartCheckoutIcon /> <span>{totalUniqueItems} Item(s)</span></p>
                <Box className='bg-[#fff] text-[#2858a3] p-1.5 mt-1.5' style={{ fontSize: '12px', borderRadius: '4px' }}> AED {cartTotal}</Box>
            </Box>
        </Fab>
    )

    const CartDrawer = (
        <Drawer
            anchor={'right'}
            open={open}
            onClose={toggleDrawer(false)}
        >
            <DrawerContent setOpen={setOpen} />
        </Drawer>
    )

    return (
        <>
            {/* {isLoggedIn && */}
                <>
                    {FloatingButton}
                    {CartDrawer}
                </>
            {/* } */}
        </>
    )
}

export default FloatingCart