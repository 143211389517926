import React from "react";
import {
  Box,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  CardActions,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import { styled } from "@mui/system";

// Styled components for hover effect
const StyledCard = styled(Card)({
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  "&:hover": {
    transform: "scale(1.05)",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
  },
});

// Catalogue data
const catalogues = [
  {
    name: "Buraq Product Catalogue",
    file: require("../assets/catalogue/BURAQ_PRODUCT.pdf"), 
    image: require("../assets/catalogue/banner/buraq.jpg"), 
  },
  {
    name: "Cavil Product Catalogue",
    file: require("../assets/catalogue/CAVIL_PRODUCT.pdf"),
    image: require("../assets/catalogue/banner/cavil.jpg"),
  },
  {
    name: "Novex Ceramic Product Catalogue",
    file: require("../assets/catalogue/NOVEX_CERAMIC_PRODUCT.pdf"),
    image: require("../assets/catalogue/banner/novex.jpg"),
  },
  {
    name: "Novex Electrical Product Catalogue",
    file: require("../assets/catalogue/NOVEX_ELECTRICAL_PRODUCT.pdf"),
    image: require("../assets/catalogue/banner/novexelectrical.jpg"),
  },
  {
    name: "Novex Fan Product Catalogue",
    file: require("../assets/catalogue/NOVEX_FAN_PRODUCT.pdf"),
    image: require("../assets/catalogue/banner/novexfan.jpg"),
  },
  {
    name: "Novex Lighting Product Catalogue",
    file: require("../assets/catalogue/NOVEX_LIGHTING_PRODUCT.pdf"),
    image: require("../assets/catalogue/banner/novexlighting.jpg"),
  },
  {
    name: "Sanitary Faucet Catalogue",
    file: require("../assets/catalogue/SANITARY_FAUCET.pdf"),
    image: require("../assets/catalogue/banner/sanitaryfaucet.jpg"),
  },
  {
    name: "Zilco Product Catalogue",
    file: require("../assets/catalogue/ZILCO_PRODUCT.pdf"),
    image: require("../assets/catalogue/banner/zilco.jpg"),
  },
];

const Catalogue = () => {
  return (
    <Box sx={{ padding: "2rem", backgroundColor: "#f8f9fa" }}>
      {/* Header */}
      <Typography
        variant="h4"
        align="center"
        sx={{
          fontWeight: 700,
          marginBottom: "2rem",
          color: "#2858A3",
          textTransform: "uppercase",
        }}
        className="poppins"
      >
        Our Catalogues
      </Typography>

      {/* Catalogue Grid */}
      <Grid container spacing={3}>
        {catalogues.map((catalogue, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <StyledCard sx={{ borderRadius: "12px" }}>
              {/* Thumbnail */}
              <CardMedia
                component="img"
                height="200"
                image={catalogue.image || "/default-pdf-thumbnail.jpg"} // Fallback to default
                alt={catalogue.name}
              />
              {/* Details */}
              <CardContent>
                <Typography
                  variant="h6"
                  component="div"
                  className="poppins"
                  sx={{
                    color: "#333",
                    fontWeight: 600,
                    textAlign: "center",
                    fontSize: "1rem",
                  }}
                >
                  {catalogue.name}
                </Typography>
              </CardContent>
              {/* Actions */}
              <CardActions
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: 2,
                  paddingBottom: "1rem",
                }}
              >
                {/* View Button */}
                <Button
                  variant="contained"
                  startIcon={<VisibilityIcon />}
                  sx={{
                    backgroundColor: "#2858A3",
                    color: "#fff",
                    "&:hover": { backgroundColor: "#1E4682" },
                  }}
                  href={catalogue.file}
                  target="_blank"
                >
                  View
                </Button>
                {/* Download Button */}
                <Button
                  variant="outlined"
                  startIcon={<DownloadIcon />}
                  sx={{
                    borderColor: "#2858A3",
                    color: "#2858A3",
                    "&:hover": {
                      backgroundColor: "#2858A3",
                      color: "#fff",
                      borderColor: "#2858A3",
                    },
                  }}
                  href={catalogue.file}
                  download
                >
                  Download
                </Button>
              </CardActions>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Catalogue;
