import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import ajaxService from "../services/ajax-service";
import { BRANDS, CATEGORIES } from "../constants";
import { Notification } from "../components/AddToWishlist";
import {
  HomeSlider,
  CategorySlider,
  Qualities,
  BrandBanner,
  PopularSlider,
  BrandsBanner,
  MobilePopularSlider,
  SaleSection,
  Popular,
  OffersBanner,
  SEOInstagram
} from "../components";

import SkeletonHomeSlider from "../components/Home/skeleton/SkeletonHomeSlider";
import SkeletonCategorySlider from "../components/Home/skeleton/SkeletonCategorySlider";
import SkeletonBrandBanner from "../components/Home/skeleton/SkeletonBrandBanner";
import SkeletonPopularSlider from "../components/Home/skeleton/SkeletonPopularSlider";
import SkeletonBrandsBanner from "../components/Home/skeleton/SkeletonBrandsBanner";
import SkeletonSaleSection from "../components/Home/skeleton/SkeletonSaleSection";
import SkeletonPopular from "../components/Home/skeleton/SkeletonPopular";
import SkeletonOffersBanner from "../components/Home/skeleton/SkeletonOffersBanner";
import SkeletonSEOInstagram from "../components/Home/skeleton/SkeletonSEOInstagram";
import useScrollAnimation from "../hooks/ScrollAnimation";



const Home = () => {
  const [wishlistNotify, setWishlistNotify] = useState(false)

  const [flashProducts, setFlashProducts] = useState([]);
  const [hotProducts, setHotProducts] = useState([]);
  const [novexProducts, setNovexProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(true);

  const [homeSliderRef, homeSliderClass] = useScrollAnimation(
    "opacity-0 translate-y-10",
    "opacity-100 translate-y-0 transition duration-700 ease-in-out",
    !loading
  );

  const [categorySliderRef, categorySliderClass] = useScrollAnimation(
    "opacity-0 translate-x-10",
    "opacity-100 translate-x-0 transition duration-700 ease-in-out",
    !loading
  );

  const [qualitiesRef, qualitiesClass] = useScrollAnimation(
    "opacity-0 scale-75",
    "opacity-100 scale-100 transition duration-700 ease-in-out",
    !loading
  );

  const [brandBannerRef, brandBannerClass] = useScrollAnimation(
    "opacity-0 -translate-x-10",
    "opacity-100 translate-x-0 transition duration-700 ease-in-out",
    !loading
  );

  const [popularSliderRef, popularSliderClass] = useScrollAnimation(
    "opacity-0 translate-y-10",
    "opacity-100 translate-y-0 transition duration-700 ease-in-out",
    !loading
  );

  const [mobilePopularSliderRef, mobilePopularSliderClass] = useScrollAnimation(
    "opacity-0 translate-x-10",
    "opacity-100 translate-x-0 transition duration-700 ease-in-out",
    !loading
  );

  const [brandsBannerRef, brandsBannerClass] = useScrollAnimation(
    "opacity-0 scale-75",
    "opacity-100 scale-100 transition duration-700 ease-in-out",
    !loading
  );

  const [saleSectionRef, saleSectionClass] = useScrollAnimation(
    "opacity-0 translate-y-10",
    "opacity-100 translate-y-0 transition duration-700 ease-in-out",
    !loading
  );

  const [popularRef, popularClass] = useScrollAnimation(
    "opacity-0 translate-x-10",
    "opacity-100 translate-x-0 transition duration-700 ease-in-out",
    !loading
  );

  const [offersBannerRef, offersBannerClass] = useScrollAnimation(
    "opacity-0 -translate-y-10",
    "opacity-100 translate-y-0 transition duration-700 ease-in-out",
    !loading
  );

  const [seoInstagramRef, seoInstagramClass] = useScrollAnimation(
    "opacity-0 scale-75",
    "opacity-100 scale-100 transition duration-700 ease-in-out",
    !loading
  );


  const loadBrands = async () => {
    const { success, data } = await ajaxService.get('/all-brands');
    if (success) {
      setBrands(data)
    }
  }

  const loadCategories = async () => {
    const response = await ajaxService.get('/all-categories');
    if (response.success) {
      setCategories([{ id: 0, name: 'View All Items' }, ...response.data])
    }
  }

  const loadProducts = async () => {
    const user = localStorage.getItem('user') ?? null;
    const response = await ajaxService.get(`/category/products?category_id=0&type=${CATEGORIES.ALL}${localStorage.getItem('token') && user ? "&user_id=" + JSON.parse(user).id : ''}`);
    const { success, data } = response;
    if (success) {
      setFlashProducts(data.filter(i => i.flash));
      setNovexProducts(data.filter(i => i.brand === BRANDS.NOVEX));
      setHotProducts(data.filter(i => i.hot))
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([loadCategories(), loadProducts(), loadBrands()]);
      setLoading(false); // Set loading to false once all data is loaded
    };

    fetchData();
  }, [])

  return (
    <>
      <Box className="w-full h-auto m-0 p-0">

        {loading ? <SkeletonHomeSlider /> : <div ref={homeSliderRef} className={homeSliderClass}><HomeSlider /> </div>}

        {loading ? <SkeletonCategorySlider /> : <div ref={categorySliderRef} className={categorySliderClass}> <CategorySlider categories={categories} />      </div>}

        <div ref={qualitiesRef} className={qualitiesClass}>
          <Qualities />
        </div>

        {loading ? <SkeletonBrandBanner /> : <div ref={brandBannerRef} className={brandBannerClass}><BrandBanner /></div>}

        {loading ? <SkeletonPopularSlider /> : (
          <div ref={popularSliderRef} className={popularSliderClass}>
            <PopularSlider
              novexProducts={novexProducts}
              setNovexProducts={setNovexProducts}
              wishlistNotify={wishlistNotify}
              setWishlistNotify={setWishlistNotify}
            />
            <div ref={mobilePopularSliderRef} className={mobilePopularSliderClass}>
              <MobilePopularSlider />
            </div>
          </div>
        )}

        {loading ? <SkeletonBrandsBanner /> : <div ref={brandsBannerRef} className={brandsBannerClass}><BrandsBanner brands={brands} /> </div>}

        {loading ? <SkeletonSaleSection /> : (<div ref={saleSectionRef} className={saleSectionClass}>
          <SaleSection
            hotProducts={hotProducts}
            flashProducts={flashProducts}
          />
        </div>
        )}

        {loading ? <SkeletonPopular /> : <div ref={popularRef} className={popularClass}><Popular />     </div>}

        {loading ? <SkeletonOffersBanner /> :<div ref={offersBannerRef} className={offersBannerClass}> <OffersBanner />   </div>}

        {loading ? <SkeletonSEOInstagram /> : <div ref={seoInstagramRef} className={seoInstagramClass}><SEOInstagram /></div>}

      </Box>

      <Notification open={wishlistNotify} setOpen={setWishlistNotify} />
    </>
  );
};

export default Home;
