import React, { useState } from "react";
import { Box, Button, Divider, Grid, IconButton, Typography } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import AddToCart from "../AddToCart";
import { Link } from 'react-router-dom';

const styles = {
  container: {
    maxHeight: '3em', // Adjust according to your preference
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2, // Limit to 2 lines
  },
};

const Content = ({ product }) => {
  const [quantity, setQuantity] = useState(1)

  return (
    <Box className="h-full w-full px-0 sm:px-10">
      <Box className="w-fit border border-[#FF3030] px-1.5 poppins text-xs text-[#FF3030] uppercase bg-transparent">
        {product.stocks > 0 ? "Stock Availability" : "Stock Unavailability"}
      </Box>
      <Typography className="poppins text-3xl font-bold w-full sm:w-3/4 text-[#2E2E2E] capitalize py-2">
        {product.name}
      </Typography>
      <Typography className="poppins text-xs text-[#02ADEC] capitalize py-1.5">
        By {product.brand}
      </Typography>
      <Typography className="poppins text-xs text-[#1E55AC] capitalize py-1.5">
        {product.code}
      </Typography>
      <Typography className="poppins text-xs text-[#5D5D5D] capitalize py-1.5">
        <span className="pe-1">Availability: </span>{product.stocks} Available in Stock
      </Typography>
      <Divider className="bg-[#eff0f0] mt-6 mb-4" />
      <Box className="flex">
        <Typography className="poppins text-sm text-[#2E2E2E] capitalize py-1.5 pe-2">
          Tag:
        </Typography>
        <Typography className="poppins text-sm text-[#5D5D5D] capitalize py-1.5">
          {product.tags}
        </Typography>
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={5}>
          <Box className="flex items-center">
            <Typography className="poppins text-sm sm:text-base text-[#2E2E2E] capitalize py-1.5 pe-2">
              Choose Quantity:
            </Typography>
            <Box className="flex items-center justify-between bg-transparent border rounded-md px-3 py-1.5">
              <IconButton size="small" className="">
                <Remove className="text-sm text-black" onClick={() => (quantity !== 1) && setQuantity(quantity - 1)} />
              </IconButton>
              <span className="px-4">{quantity}</span>
              <IconButton size="small" className="">
                <Add className="text-sm text-black" onClick={() => (quantity !== product.stocks) && setQuantity(quantity + 1)} />
              </IconButton>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <AddToCart
            className="bg-[#1E55AC]  py-2.5 px-16 text-white poppins capitalize"
            quantity={quantity}
            product={product}
          />
        </Grid>

        <Grid item xs={3}>
          <Button variant="contained"
            component={Link}
            to="/contact"
            className="bg-[#1E55AC]  py-2.5 px-8 text-white poppins capitalize"            >
            Bulk Inquiry
          </Button>
        </Grid>
      </Grid>
      
      <Box className="flex items-center gap-x-3 my-3">
        {product.discount_percentage &&
          <Box className="">
            <Typography className="poppins text-sm text-[#9AA5B3] capitalize">
              {product.discount_percentage}% Off
            </Typography>
            <Typography className="poppins text-base line-through text-[#FF3030] capitalize">
              {product.price} {product.currency}
            </Typography>
          </Box>
        }
        <Typography className="poppins text-4xl font-bold text-[#1E55AC] uppercase py-1.5">
          {product.currency} {product.discount_price ?? product.price}
        </Typography>
        <Typography className="poppins text-sm text-[#9AA5B3] capitalize">
          (05) Reviews
        </Typography>
      </Box>
      <Typography className="poppins text-sm text-[#5D5D5D] capitalize py-1.5" style={styles.container}>
        <div dangerouslySetInnerHTML={{ __html: product.description }} />
      </Typography>
      {/* <Box className="gap-y-4 mt-6">
        {features.map((feat, i) => (
          <Box className="flex gap-x-6" key={i}>
            <Typography className="poppins text-sm text-[#5D5D5D] w-20 uppercase">
              {feat.title}
            </Typography>
            <Typography className="poppins text-sm text-[#5D5D5D] uppercase">
              {feat.value}
            </Typography>
          </Box>
        ))}
      </Box> */}
    </Box>
  );
};

export default Content;
