import React, { useState, useEffect, useContext } from "react";
import { Box, Badge, Button, Divider, IconButton, Typography } from "@mui/material";
// import { Add, Remove } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import { CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import { AuthContext } from "../../AuthContext";
import axios from "axios";
import { BASE_URL, ImageURL } from "../../constants";
import { useCart } from "react-use-cart";
import { Add, Remove } from "@mui/icons-material";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const DrawerContent = ({ setOpen }) => {
    const { CartId } = useContext(AuthContext);
    // const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const { totalUniqueItems, cartTotal, items, updateItemQuantity, emptyCart, isEmpty } = useCart();

    // useEffect(()=> {
    //     if(CartId) {
    //         setLoading(true)
    //         axios.post(`${BASE_URL}/api/v1/carts`, {
    //             cart_id: CartId,
    //             type: ''
    //         },{
    //             headers: {
    //                 Authorization: `Bearer ${localStorage?.getItem('token')}`
    //             },
    //         })
    //         .then((res) => {
    //             const products = res?.data?.cart_items.map((item) => {
    //                 return {
    //                     cart_id: item?.id,
    //                     product: item?.product,
    //                     quantity: item?.quantity
    //                 };
    //             });
    //             setItems(products)
    //             setLoading(false)
    //         })
    //         .catch((error) => {
    //           console.error("Error:", error);
    //           setLoading(false)
    //         });
    //     }
    // }, [CartId])

    const Header = (
        <>
            <Box className="p-5" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box style={{ display: 'flex' }}>
                    <ShoppingCartCheckoutIcon className='text-[#2858a3]' style={{ fontWeight: 900, fontSize: '2.5em' }} />
                    <p style={{ fontWeight: 900, fontSize: 'px' }}>{totalUniqueItems} Item(s)</p>
                </Box>
                <IconButton onClick={() => setOpen(false)}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Divider />
        </>
    )

    const Footer = (
        <>
            <Divider />
            <Box style={{ padding: '15px 15px 15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <p className="text-[14px]" style={{ textOverflow: "ellipsis", overflow: "hidden", width: "126.5px", whiteSpace: "nowrap" }}> Sub Total </p>
                <p className="text-[14px]"> AED {cartTotal.toFixed(2)}</p>
            </Box>
            <Divider />
            <Box className="p-5" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button component={Link} to="/checkout" onClick={() => setOpen(false)} variant='contained' className="w-[100%]" style={{ textTransform: "capitalize", background: '#2858A3', fontWeight: 800 }}>
                    Checkout
                </Button>
            </Box>
        </>
    )

    const content = (
        <>
            {
                items.map((row, index) => (
                    <>
                        <Box key={`box-${index}`} style={{ padding: '15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <IconButton size="small" className="bg-[#2858A3]" onClick={() => updateItemQuantity(row.id, row.quantity - 1)}>
                                <Remove className="text-sm text-white" />
                            </IconButton>
                            <Badge badgeContent={row.quantity} color="primary">
                                <img src={`${ImageURL}${row?.img}`} height="70px" width='70px' />
                            </Badge>
                            <IconButton size="small" className="bg-[#2858A3]" onClick={() => updateItemQuantity(row.id, (row.quantity + 1) > row.stocks ? row.stocks : (row.quantity + 1))}>
                                <Add className="text-sm text-white" />
                            </IconButton>
                            <Box>
                                <p className="text-[12px]" style={{ textOverflow: "ellipsis", overflow: "hidden", width: "150px", whiteSpace: "nowrap" }}>{row?.name}</p>
                            </Box>
                            <Box className="text-[14px]">
                                <p className="text-[#2858A3]">{row.itemTotal.toFixed(2)} {row?.product?.currency ? row?.product?.currency : 'AED'}</p>
                            </Box>
                        </Box>
                        <Divider />
                    </>
                ))
            }
        </>
    )

    return (
        <Box
            sx={{
                width: '430px',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
            }}
            role="presentation"
        >
            {Header}
            {
                !isEmpty ? <Button
                    variant="outlined"
                    color="primary"
                    onClick={emptyCart}
                    sx={{ m: 2 }}
                    startIcon={<DeleteOutlineIcon />}
                >
                    Empty Cart
                </Button> : ""
            }

            <Box sx={{ flex: '1 1 auto', overflow: 'auto', paddingX: 2 }}>
                {
                    loading ?
                        <div className="w-full h-full flex justify-center items-center">
                            <CircularProgress size={150} className="text-white" />
                        </div> :
                        !loading && items?.length === 0 ?
                            <Typography className="poppins font-semibold uppercase text-[32px] text-black w-full h-full flex justify-center items-center">
                                Your Cart is Empty
                            </Typography> :
                            <> {content} </>
                }
            </Box>
            {
                !isEmpty ? Footer : null
            }
        </Box>
    )
}

export default DrawerContent