import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Badge, Box, Divider, Drawer, Grid, IconButton, ListItem, ListItemButton, Typography, debounce, styled } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import MenuIcon from "@mui/icons-material/Menu";
import { useCart } from "react-use-cart";

import logo from "./../../assets/burraqstar.svg";
import { routes } from "../../data";
import ajaxService from "../../services/ajax-service";
import { CATEGORIES, ImageURL } from "../../constants";
import { AuthContext } from "../../AuthContext";
import axios from "axios";
import { BASE_URL } from "../../constants";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 13,
    border: `2px solid white`,
    padding: "0 4px"
  }
}));

const HeaderBar = () => {
  const { logout, CartId } = useContext(AuthContext);

  const navigate = useNavigate()
  const isLoggedIn = localStorage.getItem('token') && localStorage.getItem('token') !== null ? true : false
  // const { totalUniqueItems } = useCart()
  const { totalUniqueItems } = useCart();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // const [totalItems, setTotalItems] = useState(0);

  const [searchOpen, setSearchOpen] = useState(false)
  const [search, setSearch] = useState('')
  // Drawer
  const [open, setOpen] = useState(false)
  // products
  const [products, setProducts] = useState([])

  const handleLogout = async () => {
    ajaxService.get('/auth/logout', 0, true)
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    localStorage.removeItem('expires_at')
    logout()
    navigate('/')
  }

  const handleInputBlur = () => {
    setTimeout(() => {
      setSearch('')
    }, 200)
  }

  const handleProductClick = async slug => {
    ajaxService.get('/product/search-log/' + slug)
    navigate('/product/' + slug)
  }

  // const loadProducts = async () => {
  //   const response = await ajaxService.get(
  //     `/category/products?category_id=0&type=${CATEGORIES.ALL}`
  //   )
  //   const { success, data } = response
  //   if (success) {
  //     setProducts(data)
  //   }
  // }


  // Function to handle API call
  const fetchProducts = async (query) => {
    if (!query) {
      setProducts([]);
      return;
    }

    try {
      setLoading(true);
      setError(null);

      const response = await ajaxService.get(
        `/product-search?q=${query}`
      )

      setProducts(response);
    } catch (err) {
      setError("Failed to fetch products. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Debounced version of fetchProducts to optimize API calls
  const debouncedFetchProducts = debounce((query) => fetchProducts(query), 500);

  // Handle input changes and trigger the debounced search
  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearch(query);
    debouncedFetchProducts(query);
  };


  // useEffect(() => {
  //   loadProducts()
  // }, [])

  //   useEffect(()=> {
  //     axios.post(`${BASE_URL}/api/v1/carts`, {
  //         cart_id: CartId,
  //         type: ''
  //     },{
  //         headers: {
  //             Authorization: `Bearer ${localStorage?.getItem('token')}`
  //         },
  //     })
  //     .then((res) => {
  //         const products = res?.data?.cart_items.map((item) => {
  //             return {
  //                 cart_id: item?.id
  //             };
  //         });
  //         setTotalItems(products?.length)
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     });
  // }, [CartId])

  const DrawerList = (
    <Box
      sx={{ width: 250 }}
      className='flex flex-col h-full justify-center px-5'
      role='presentation'
      onClick={() => setOpen(false)}
    >
      <Link to={'/'}>
        <img
          src={logo}
          className='h-[54px] md:h-[62px] lg:h-[70px] w-auto mb-8'
          alt='burraq star logo'
        />
      </Link>
      {routes.map((route, i) => (
        <>
          <Link to={route.link} key={i} className='flex items-center'>
            {route?.mobIcon && (
              <span className='text-lg text-[#2858A3] -mt-1 pe-4'>
                {route?.mobIcon}
              </span>
            )}
            <Typography className='poppins text-base text-black'>
              {route.title}
            </Typography>
          </Link>
          <Divider className='my-4' />
        </>
      ))}
    </Box>
  )

  return (
    <Box className='h-20 sm:h-28 w-full relative top-0 bg-white gap-x-20 flex items-center justify-center px-2.5 sm:px-9 sm:py-5 '>
      <Box className='flex items-center gap-x-3'>
        <IconButton
          className='p-0 block sm:block lg:hidden'
          onClick={() => setOpen(true)}
        >
          <MenuIcon className='text-3xl text-[#2858A3]' />
        </IconButton>
        <Drawer open={open} onClose={() => setOpen(false)}>
          {DrawerList}
        </Drawer>
        <Link to='/'>
          <img
            src={logo}
            className='h-[54px] md:h-[62px] lg:h-[80px] w-auto'
            alt='burraq star logo'
          />
        </Link>
      </Box>

      <Box className='flex relative items-center h-full gap-x-2 sm:gap-x-5 sm:w-[65%] '>

        <Box className="relative w-[62%] h-[85%] bg-[#f0f0f0] rounded-lg hidden sm:flex items-center justify-between px-8 py-4">
          {/* Input Field */}
          <input
            className="w-[80%] bg-transparent outline-none text-[#2858A3]"
            placeholder="Search Here..."
            value={search}
            onChange={handleSearchChange}
          />
          <SearchIcon className="text-2xl text-[#2858A3]" />

          {/* Dropdown */}
          {search && (
            <Box
              className="absolute top-full mt-2 left-0 rounded-lg z-[1050] w-full max-h-[50vh] overflow-y-auto bg-white shadow-lg"
            >
              {loading && (
                <Typography className="text-center text-gray-500 p-4">
                  Searching...
                </Typography>
              )}

              {products.length > 0 ? (
                products.map((product, i) => (
                  <ListItem key={product.slug || `key-${i}`} disablePadding   onClick={() => handleProductClick(product.slug)}>
                    <ListItemButton className="rounded">
                      <Box className="flex w-full h-fit">
                        <img
                          src={ImageURL + (product.images?.[0] || "/default-image.jpg")}
                          alt={product.name}
                          className="h-10 w-10"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "/default-image.jpg";
                          }}
                        />
                        <Typography className="text-xs ps-3 text-black poppins">
                          {product.name}
                        </Typography>
                      </Box>
                    </ListItemButton>
                  </ListItem>
                ))
              ) : (
                !loading && (
                  <Typography className="text-center text-gray-500 p-4">
                    No products found.
                  </Typography>
                )
              )}
            </Box>
          )}
        </Box>




        <SearchIcon
          className='cursor-pointer text-2xl text-[#2858A3] block sm:hidden'
          onClick={() => setSearchOpen(!searchOpen)}
        />

        {/* <Link to='/'>
          <GridViewOutlinedIcon className='text-2xl sm:text-4xl text-[#4C4C4C] cursor-pointer' />
        </Link> */}

        <Link to='/cart'>
          <IconButton>
            <StyledBadge badgeContent={totalUniqueItems} color='primary'>
              <ShoppingCartIcon className='text-[#2858A3] text-2xl sm:text-4xl' />
            </StyledBadge>
          </IconButton>
        </Link>

        {/* <Link to="/" className="h-full flex items-center">
          <Button className="min-w-fit h-[85%] rounded-full sm:rounded-full lg:rounded-lg px-1 sm:px-6 gap-x-0 sm:gap-x-3 text-white bg-[#2858a3] cursor-pointer flex items-center">
            <AccountCircleIcon className="text-2xl sm:text-4xl" />
            <Typography className="poppins font-semibold hidden sm:hidden lg:block">
              My Account
            </Typography>
          </Button>
        </Link> */}

        <div className='h-full flex items-center ml-2'>
          <Link
            to={isLoggedIn ? '/user/dashboard' : '/user/login'}
            className='h-full flex items-center'
          >
            <Typography className='poppins font-semibold hidden sm:hidden lg:block'>
              {isLoggedIn ? 'Dashboard' : 'Login'}
            </Typography>
          </Link>
          <Typography className='poppins hidden sm:hidden lg:block mx-3'>
            Or
          </Typography>
          {isLoggedIn ? (
            <Typography
              className='poppins font-semibold h-full flex items-center cursor-pointer'
              onClick={handleLogout}
            >
              Logout
            </Typography>
          ) : (
            <Link to='/user/registration' className='h-full flex items-center'>
              <Typography className='poppins font-semibold hidden sm:hidden lg:block'>
                Registration
              </Typography>
            </Link>
          )}
        </div>
      </Box>


      {/* Mobile Search */}
      {searchOpen && (
        <Box className='h-16 w-full z-50 absolute -bottom-[65px] left-0 flex items-center sm:hidden p-2.5 bg-[#f0f0f0]'>
          <Box className='w-full h-full bg-white rounded-lg flex items-center justify-between px-8 py-4'>
            <input
              className='w-[80%] bg-transparent outline-none text-[#2858A3]'
              placeholder='Search Here...'
              value={search}
              onChange={e => setSearch(e.target.value)}
              onBlur={handleInputBlur}
            />
            <SearchIcon className=' text-2xl text-[#2858A3]' />
          </Box>
          {search.length > 0 && (
            <Box className='absolute top-[68px] border-2 left-0 rounded-lg z-50 w-full h-[50vh] overflow-y-auto bg-white'>
              {products
                .filter(prod =>
                  prod.name.toLowerCase().includes(search?.toLowerCase())
                )
                .map((product, i) => (
                  <ListItem
                    key={'key-' + i}
                    disablePadding
                    onClick={() => handleProductClick(product.id)}
                  >
                    <ListItemButton className='rounded'>
                      <Box className='flex w-full h-fit' key={i}>
                        <img
                          src={ImageURL + product.images[0] ?? null}
                          alt={product.name}
                          className='h-10 w-10'
                        />
                        <Typography className='text-xs ps-3 text-black poppins'>
                          {product.name}
                        </Typography>
                      </Box>
                    </ListItemButton>
                  </ListItem>
                ))}
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
}

export default HeaderBar
