import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import ajaxService from '../../../services/ajax-service';
import { CircularProgress } from '@mui/material';
import { NotificationBar } from '../../../components';
import routes from '../../../utils';

const defaultTheme = createTheme();

const Registration = () => {
    const navigate = useNavigate();

    // for notification
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    // loading
    const [loading, setLoading] = useState(false)

    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        password: '',
        cPassword: '',
    });
    const [errors, setErrors] = useState({});

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        // Clear previous error message when user starts typing
        setErrors({
            ...errors,
            [name]: '',
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const { name, phone, email, password, cPassword } = formData;
        const newErrors = {};

        // Validation rules
        if (!name.trim()) {
            newErrors.name = 'Full Name is required';
        }
        if (!phone.trim()) {
            newErrors.phone = 'Phone Number is required';
        }
        if (!email.trim()) {
            newErrors.email = 'Email Address is required';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            newErrors.email = 'Email Address is invalid';
        }
        if (!password.trim()) {
            newErrors.password = 'Password is required';
        }
        if (!cPassword.trim()) {
            newErrors.cPassword = 'Confirm Password is required';
        } else if (password !== cPassword) {
            newErrors.cPassword = 'Passwords do not match';
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            setLoading(true)
            const data = {
                name: formData.name,
                email: formData.email,
                phone: formData.phone,
                password: formData.password,
                confirmPassword: formData.cPassword,
                invalidPhone: false,
                showInvalidPhone: false
            }

            const response = await ajaxService.post('/auth/signup', data);

            setLoading(false)

            if (!response.success) {
                setMessage(response.message);
                setOpen(true);
            } else {
                localStorage.setItem('registerEmail', data.email)
                navigate('/user/verify-account')
            }
        }
    };

    return (
        <>
            <ThemeProvider theme={defaultTheme}>
                <Grid className='poppins my-7' container component="main" sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CssBaseline />
                    <Grid
                        item
                        xs={false}
                        sm={4}
                        md={4}
                        sx={{
                            backgroundImage: 'url(https://source.unsplash.com/random?wallpapers)',
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: (t) =>
                                t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    />
                    <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
                        <Box
                            sx={{
                                my: 8,
                                mx: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                // alignItems: 'center',
                            }}
                        >
                            <Typography component="h1" variant="h6" style={{ color: '#b5b5bf' }}>
                                WELCOME TO
                            </Typography>
                            <Typography component="h1" variant="h4" className='poppins' style={{ color: '#2858a3', fontWeight: 900 }}>
                                BURAQ STAR
                            </Typography>

                            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="name"
                                    label="Full Name"
                                    name="name"
                                    autoComplete="name"
                                    autoFocus
                                    error={!!errors.name}
                                    helperText={errors.name}
                                    value={formData.name}
                                    onChange={handleChange}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="phone"
                                    label="Phone Number"
                                    name="phone"
                                    autoComplete="phone"
                                    error={!!errors.phone}
                                    helperText={errors.phone}
                                    value={formData.phone}
                                    onChange={handleChange}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    error={!!errors.email}
                                    helperText={errors.email}
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    error={!!errors.password}
                                    helperText={errors.password}
                                    value={formData.password}
                                    onChange={handleChange}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="Confirm Password"
                                    type="password"
                                    id="cPassword"
                                    name="cPassword"
                                    autoComplete="current-password"
                                    error={!!errors.cPassword}
                                    helperText={errors.cPassword}
                                    value={formData.cPassword}
                                    onChange={handleChange}
                                />
                                <Button
                                    className='poppins bg-[#2858a3] w-[50%]'
                                    style={{ height: '50px', textTransform: 'capitalize' }}
                                    // fullWidth
                                    type="submit"
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    disabled={loading}
                                >
                                    {loading && <CircularProgress size={25} className="text-white" />}
                                    {!loading && "Create Account"}
                                </Button>


                                <Grid container>
                                    <Grid item xs>
                                        <Link className='cursor-pointer' onClick={() => navigate('/login')} variant="body2">
                                            {"Already have an account? Login"}
                                        </Link>
                                    </Grid>
                                    <Grid item>
                                        <Link className='cursor-pointer' onClick={() => navigate(routes.retailer_registration)} variant="body2">
                                            {"Apply for Retailer Account"}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </ThemeProvider>

            {/* notification */}
            <NotificationBar
                open={open}
                setOpen={setOpen}
                type="error"
                message={message}
            />
        </>
    );
}

export default Registration;