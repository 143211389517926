import { MapStyle } from "./googlemapstyle";

// Base URL
export const ImageURL = 'https://buraq.frappe.cloud';
export const BASE_URL = 'https://buraqstar.digitaezonline.com';
// export const BASE_URL = 'http://buraq.local';
// export const BASE_URL = 'http://127.0.0.1:8000';
// export const BASE_URL = 'http://192.168.0.165:3366';

export const REACT_APP_GOOGLE_MAPS_API_KEY="AIzaSyBklxYBuqfHt6UD_mHtjp9zacwaVWhldZA"
export const GoogleMapStyle =  MapStyle

// Category Types
export const CATEGORIES = {
    ALL: 'all',
    NEW_ARRIVAL: 'new_arrival',
    FLASH_SALE: 'flash-sale',
    PARENT: 'parent',
    CHILD: 'child',
    SUB_CHILD: 'sub_child'
}

export const BRANDS = {
    BURAQ: 'BURAQ',
    CAVIL: 'CAVIL',
    NOVEX: 'NOVEX',
    ZILCO: 'ZILCO'
}   