import React from "react";
import { Box, Typography, Container, Paper } from "@mui/material";
import { Hero } from "../components";
import bg from "../assets/policy_banner.jpg";

const PrivacyPolicy = () => {
  const policySections = [
    {
      title: "Information We Collect",
      description: `
        - Personal Information: Name, email address, phone number, shipping and billing addresses.
        - Payment Information: Credit/debit card details processed securely by third-party payment providers.
        - Account Information: Login credentials for registered users.
        - Transaction Information: Purchase history and order details related to tools, electrical, sanitary, and hardware products.
        - Device and Usage Information: IP address, browser type, and browsing activities through cookies.`,
    },
    {
      title: "How We Use Your Information",
      description: `
        - To process and fulfill orders, including payment processing and delivery of tools, electrical, sanitary, and hardware products.
        - To communicate with you about your orders, promotions, or updates related to our product categories.
        - To personalize your shopping experience on our website.
        - To improve our website, products, and services.
        - To comply with legal obligations and prevent fraudulent activities.`,
    },
    {
      title: "Sharing of Information",
      description: `
        - We do not sell, trade, or rent your personal information to third parties.
        - Service Providers: Payment processors, delivery partners, and marketing service providers.
        - Legal Authorities: When required by law or to protect our legal rights.`,
    },
    {
      title: "Data Security",
      description: `We take reasonable measures to protect your information from unauthorized access, alteration, or disclosure. However, no method of transmission over the internet is completely secure, so we cannot guarantee absolute security.`,
    },
    {
      title: "Cookies and Tracking Technologies",
      description: `We use cookies to enhance your experience on our website. Cookies help us analyze site traffic, remember your preferences, and offer personalized content. You can manage your cookie preferences through your browser settings.`,
    },
    {
      title: "Your Rights",
      description: `
        - Access, correct, or delete your personal information.
        - Opt-out of receiving marketing communications.
        - Disable cookies through browser settings.
        - To exercise your rights, please contact us at ecommerce@buraqstar.com`,
    },
    {
      title: "Third-Party Links",
      description: `Our website may contain links to third-party websites. We are not responsible for the privacy practices of those sites.`,
    },
    {
      title: "Children’s Privacy",
      description: `Our website is not intended for children under the age of 13. We do not knowingly collect personal information from children.`,
    },
    {
      title: "Changes to this Privacy Policy",
      description: `We may update this policy from time to time. Any changes will be posted on this page with an updated effective date.`,
    },
    {
      title: "Contact Us",
      description: `
        Buraq Star Trading Co. LLC
        Email: ecommerce@buraqstar.com
        Phone: 800 66839 (Toll free)`,
    },
  ];

  return (
    <Box className="w-full bg-gray-50 min-h-screen">
      <Hero bg={bg} title="Privacy Policy" />
      <Container maxWidth="lg" className="py-10 px-4 sm:px-6 md:px-8">
        <Paper elevation={3} className="p-6 rounded-lg shadow-md bg-white">
          <Typography variant="h4" className="font-semibold text-gray-800 mb-4 text-center">
            Privacy Policy for Buraq Star Trading Co. LLC
          </Typography>
          <Typography variant="subtitle1" className="text-gray-600 text-center mb-6">
            Effective Date: 2/2/2025
          </Typography>
          <Typography className="text-gray-700 leading-relaxed mb-6">
            At Buraq Star, we value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and protect the information you provide when using our online store.
          </Typography>
          {policySections.map((section, i) => (
            <Box key={i} className="mb-6">
              <Typography variant="h6" className="text-gray-800 font-bold mb-2">
                {i + 1}. {section.title}
              </Typography>
              <Typography className="text-gray-700 leading-relaxed whitespace-pre-line">
                {section.description}
              </Typography>
            </Box>
          ))}
        </Paper>
      </Container>
    </Box>
  );
};

export default PrivacyPolicy;
