import React, { useEffect, useState } from 'react';
import {
    Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, TableSortLabel, TablePagination, Paper, TextField, Skeleton, Chip,
    IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button,
    Grid
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Visibility } from '@mui/icons-material';
import axios from 'axios';
import { BASE_URL } from '../../../constants';
import waterMark from './../../../assets/watermark_panel.svg'; // Ensure the correct path and import

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontWeight: 'bold',
    color: theme.palette.common.white,
    backgroundColor: "#294e89",
}));

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const MyOrder = () => {
    const [orders, setOrders] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('code');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(true);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/api/v1/user/orders`, {
                    headers: {
                        Authorization: `Bearer ${localStorage?.getItem('token')}`
                    },
                });
                if (Array.isArray(response.data.data)) {
                    setOrders(response.data.data);
                    setFilteredOrders(response.data.data);
                } else {
                    console.error("Response data is not an array:", response.data.data);
                }
            } catch (error) {
                console.error("Error fetching orders:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchOrders();
    }, []);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearch = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchQuery(query);
        const filtered = orders.filter(order =>
            order.code.toLowerCase().includes(query)
        );
        setFilteredOrders(filtered);
        setPage(0); // Reset to the first page when search query changes
    };

    const getStatusChip = (status) => {
        let color = 'default';
        if (status === 'delivered') color = 'success';
        else if (status === 'pending') color = 'warning';
        else if (status === 'canceled') color = 'error';
        return <Chip label={status} color={color} size="small" />;
    };

    const handleViewOrder = (order) => {
        setSelectedOrder(order);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedOrder(null);
    };
    const formatDate = (timestamp) => new Date(timestamp * 1000).toLocaleString();

    return (
        <Box display="flex">
            <Box flexGrow={1} p={3} className="m-2">
                <TextField
                    label="Search by Order Code"
                    variant="outlined"
                    fullWidth
                    value={searchQuery}
                    onChange={handleSearch}
                    sx={{ mb: 4 }}
                />
                <Paper elevation={3} className="rounded-lg overflow-hidden">
                    <TableContainer>
                        <Table sx={{ minWidth: 650 }} aria-label="data table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell sortDirection={orderBy === 'code' ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === 'code'}
                                            direction={orderBy === 'code' ? order : 'asc'}
                                            onClick={(event) => handleRequestSort(event, 'code')}
                                        >
                                            Order Code
                                        </TableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" sortDirection={orderBy === 'product_count' ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === 'product_count'}
                                            direction={orderBy === 'product_count' ? order : 'asc'}
                                            onClick={(event) => handleRequestSort(event, 'product_count')}
                                        >
                                            Items
                                        </TableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" sortDirection={orderBy === 'payment_type' ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === 'payment_type'}
                                            direction={orderBy === 'payment_type' ? order : 'asc'}
                                            onClick={(event) => handleRequestSort(event, 'payment_type')}
                                        >
                                            Payment Type
                                        </TableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" sortDirection={orderBy === 'delivery_status' ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === 'delivery_status'}
                                            direction={orderBy === 'delivery_status' ? order : 'asc'}
                                            onClick={(event) => handleRequestSort(event, 'delivery_status')}
                                        >
                                            Delivery Status
                                        </TableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" sortDirection={orderBy === 'payment_status' ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === 'payment_status'}
                                            direction={orderBy === 'payment_status' ? order : 'asc'}
                                            onClick={(event) => handleRequestSort(event, 'payment_status')}
                                        >
                                            Payment Status
                                        </TableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" sortDirection={orderBy === 'grand_total' ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === 'grand_total'}
                                            direction={orderBy === 'grand_total' ? order : 'asc'}
                                            onClick={(event) => handleRequestSort(event, 'grand_total')}
                                        >
                                            Grand Total
                                        </TableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" sortDirection={orderBy === 'created_at' ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === 'created_at'}
                                            direction={orderBy === 'created_at' ? order : 'asc'}
                                            onClick={(event) => handleRequestSort(event, 'created_at')}
                                        >
                                            Created At
                                        </TableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        Actions
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {loading ? (
                                    Array.from(new Array(rowsPerPage)).map((_, index) => (
                                        <TableRow key={index}>
                                            <TableCell colSpan={8}>
                                                <Skeleton animation="wave" height={40} />
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    stableSort(filteredOrders, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => (
                                            <TableRow key={index} hover>
                                                <TableCell>{row.code}</TableCell>
                                                <TableCell align="center">
                                                    <Chip variant="outlined" color="info" size="small" label={row.product_count} />
                                                </TableCell>
                                                <TableCell align="center"> {row.payment_type}</TableCell>
                                                <TableCell align="center">{getStatusChip(row.delivery_status)}</TableCell>
                                                <TableCell align="center">{getStatusChip(row.payment_status)}</TableCell>
                                                <TableCell align="center">{`${parseFloat(row.grand_total).toFixed(2)}`}</TableCell>
                                                <TableCell align="center">{new Date(row.created_at * 1000).toLocaleString()}</TableCell>
                                                <TableCell align="center">
                                                    <IconButton onClick={() => handleViewOrder(row)}>
                                                        <Visibility />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {!loading && (
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={filteredOrders.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            className="bg-gray-100"
                        />
                    )}
                </Paper>
            </Box>
            <Dialog open={modalOpen} onClose={handleCloseModal} maxWidth="lg" fullWidth>
                <DialogTitle>Order Details</DialogTitle>
                <DialogContent dividers>
                    <div className="relative flex-grow overflow-y-auto">
                        {/* Watermark */}
                        <div
                            className="absolute inset-10"
                            style={{
                                backgroundImage: `url(${waterMark})`,
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'contain',
                                opacity: 0.4, // Adjust the opacity for a subtle watermark
                                zIndex: 0, // Ensure watermark stays behind content
                            }}
                        ></div>

                        {/* Content */}
                        <div className="relative z-10">
                            {selectedOrder && (
                                <Box className="p-8">
                                    {/* Customer and Order Info */}
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-10">
                                        <div>
                                            <Typography variant="h6" className="font-bold text-gray-900 mb-6">Order Info</Typography>
                                            <Typography variant="body1" className="text-gray-600"><strong>Order Code:</strong> {selectedOrder.code}</Typography>
                                            <Typography variant="body1" className="text-gray-600"><strong>Order Date:</strong> {formatDate(selectedOrder.created_at)}</Typography>
                                            <Typography variant="body1" className="text-gray-600"><strong>Delivery Type:</strong> {selectedOrder.delivery_type}</Typography>
                                            <Typography variant="body1" className="text-gray-600"><strong>Payment Method:</strong> {selectedOrder.payment_type.replace('_', ' ')}</Typography>
                                        </div>
                                    </div>

                                    {/* Shipping and Billing Address */}
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-10">
                                        <div>
                                            <Typography variant="h6" className="font-bold text-gray-900 mb-6">Shipping Address</Typography>
                                            <Typography variant="body1" className="text-gray-600">{selectedOrder.shipping_address.address}</Typography>
                                            <Typography variant="body1" className="text-gray-600">{selectedOrder.shipping_address.city}, {selectedOrder.shipping_address.postal_code}</Typography>
                                            <Typography variant="body1" className="text-gray-600">{selectedOrder.shipping_address.state}, {selectedOrder.shipping_address.country}</Typography>
                                        </div>
                                        <div>
                                            <Typography variant="h6" className="font-bold text-gray-900 mb-6">Billing Address</Typography>
                                            <Typography variant="body1" className="text-gray-600">{selectedOrder.shipping_address.address}</Typography>
                                            <Typography variant="body1" className="text-gray-600">{selectedOrder.shipping_address.city}, {selectedOrder.shipping_address.postal_code}</Typography>
                                            <Typography variant="body1" className="text-gray-600">{selectedOrder.shipping_address.state}, {selectedOrder.shipping_address.country}</Typography>
                                        </div>
                                    </div>

                                    {/* Order Table */}
                                    <TableContainer component={Paper} className="shadow-lg rounded-lg mb-4 border border-gray-200">
                                        <Table>
                                            <TableHead className="bg-gray-50">
                                                <TableRow>
                                                    <TableCell className="font-semibold text-gray-700">#</TableCell>
                                                    <TableCell className="font-semibold text-gray-700">Product</TableCell>
                                                    <TableCell align="right" className="font-semibold text-gray-700">QTY</TableCell>
                                                    <TableCell align="right" className="font-semibold text-gray-700">Unit Price</TableCell>
                                                    <TableCell align="right" className="font-semibold text-gray-700">Unit Tax</TableCell>
                                                    <TableCell align="right" className="font-semibold text-gray-700">Total</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {selectedOrder.products.data.map((product, index) => (
                                                    <TableRow key={product.id} className="hover:bg-gray-50">
                                                        <TableCell>{index + 1}</TableCell>
                                                        <TableCell>
                                                            <Box className="flex items-center">
                                                                <img src={"https://buraq.frappe.cloud" + product.images} alt={product.name} className="w-12 h-12 mr-4 rounded-lg object-cover" />
                                                                <span className="text-gray-800">{product.name}</span>
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell align="right" className="text-gray-800">{product.quantity}</TableCell>
                                                        <TableCell align="right" className="text-gray-800">{`AED ${parseFloat(product.price).toFixed(2)}`}</TableCell>
                                                        <TableCell align="right" className="text-gray-800">{`AED ${parseFloat(product.tax).toFixed(2)}`}</TableCell>
                                                        <TableCell align="right" className="text-gray-800">{`AED ${parseFloat(product.total).toFixed(2)}`}</TableCell>

                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                    {/* Order Summary */}
                                    <Box className="flex justify-end">
                                        <Box className="w-96 bg-gray-50 p-6 rounded-lg shadow-md border border-gray-200">
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <Typography variant="body1" className="text-gray-700"><strong>Sub Total:</strong></Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant="body1" align="right" className="text-gray-800">{`AED ${parseFloat(selectedOrder.subtotal).toFixed(2)}`}</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant="body1" className="text-gray-700"><strong>Tax:</strong></Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant="body1" align="right" className="text-gray-800">{`AED ${parseFloat(selectedOrder.tax).toFixed(2)}`}</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant="body1" className="text-gray-700"><strong>Shipping:</strong></Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant="body1" align="right" className="text-gray-800">{`AED ${parseFloat(selectedOrder.shipping_cost).toFixed(2)}`}</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant="body1" className="text-gray-700"><strong>Coupon Discount:</strong></Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant="body1" align="right" className="text-gray-800">{`AED ${parseFloat(selectedOrder.coupon_discount).toFixed(2)}`}</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant="h6" className="text-gray-900"><strong>Total:</strong></Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant="h6" align="right" className="text-gray-900">{`AED ${parseFloat(selectedOrder.grand_total).toFixed(2)}`}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                </Box>
                            )}
                        </div>
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="primary">Close</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default MyOrder;
