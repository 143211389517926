
import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Divider, TextField } from "@mui/material";
import { NotificationBar } from "../../components";
import { CircularProgress } from "@mui/material";
import { BASE_URL } from "../../constants";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useCart } from "react-use-cart";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AuthContext } from "../../AuthContext";

const OrderSummary = ({
  loading,
  setLoading,
  formData,
  paymentType,
  setErrors,
  message,
  setMessage,
  open,
  setOpen,
  shippingCost,
  selectedAddress
}) => {
  const navigate = useNavigate()
  const [type, setType] = useState('error');
  const { cartTotal, items, emptyCart, totalItems } = useCart();
  const [couponCode, setCouponCode] = useState('');
  const [appliedCoupon, setAppliedCoupon] = useState('');
  const [discountAmount, setDiscountAmount] = useState(0);
  const [isCouponVisible, setIsCouponVisible] = useState(false);
  const [couponLoading, setCouponLoading] = useState(false);
  const [orderLoading, setOrderLoading] = useState(false);
  const [couponData, setCouponData] = useState(null);
  const { isLoggedIn, login } = useContext(AuthContext)

  useEffect(() => {
    let storedCouponData = localStorage.getItem('use_coupon')
    if (storedCouponData) {
      storedCouponData = JSON.parse(storedCouponData)
      setCouponData(storedCouponData)
      setCouponCode(storedCouponData.code)
    }

  }, []);

  useEffect(() => {
    if (couponData) {
      // Function to run when couponData changes
      handleCouponDataChange();
      setAppliedCoupon(couponData.code)
    }

  }, [couponData, cartTotal, shippingCost]);


  const handleCouponDataChange = () => {
    if (couponData.type == "cart_base") {

      const startDate = new Date(couponData.start_date * 1000);
      const endDate = new Date(couponData.end_date * 1000);
      const today = new Date();

      if (today >= startDate && today <= endDate) {
        calculateDiscount()


      } else {
        setType('error');
        setMessage('This coupon is not currently valid. Please check the validity period and try again');
        setOpen(true);
      }
    } else {
      setType('error');
      setMessage('This coupon cannot be used in cart!');
      setOpen(true);
    }
    // Add any additional logic you want to run when couponData changes
  };

  const calculateDiscount = () => {
    const details = JSON.parse(couponData.details);
    const minBuy = parseFloat(details.min_buy);
    const maxDiscount = parseFloat(details.max_discount);

    if (cartTotal < minBuy) {
      setType('error');
      setMessage(`Your cart total must be at least ${minBuy} to use this coupon.`);
      setOpen(true);
      setDiscountAmount(null);
      return;
    }

    let discount = 0;
    if (couponData.discount_type === 'percent') {
      discount = (cartTotal * couponData.discount) / 100;
    } else if (couponData.discount_type === 'amount') {
      discount = couponData.discount;
    }

    if (discount > maxDiscount) {
      discount = maxDiscount;
    }
    setDiscountAmount((discount.toFixed(0)));

    // const totalAfterDiscount = cartTotal - discount;
    // console.log(`Original total: ${cartTotal}, Discount: ${discount}, Total after discount: ${totalAfterDiscount}`);
  };

  // Function to toggle visibility
  const toggleCouponVisibility = () => {
    setIsCouponVisible(!isCouponVisible);
  };

  const applyCoupon = async (event) => {
    event.preventDefault();

    const newErrors = {};
    // Mocked coupon application logic, replace with your actual logic
    if (!couponCode.trim()) {
      newErrors.couponCode = "Coupon code is required";
      setMessage(newErrors.couponCode);
      setOpen(true);
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setCouponLoading(true)

      axios.post(`${BASE_URL}/api/v1/coupon/g/apply`, {
        coupon_code: couponCode,
      }).then((response) => {
        setCouponLoading(false)
        if (!response?.data?.success) {
          setOpen(true);
          setType('error');
          setMessage(response?.data?.message);
          setOpen(true);
        } else if (response?.data?.success) {
          setCouponData(response?.data.coupon_details);
          localStorage.setItem('use_coupon', JSON.stringify(response?.data.coupon_details))
          setType('success');
          setMessage(response?.data?.message);
          setOpen(true);
        }

      })
        .catch((error) => {
          console.error("Error:", error);
          setCouponLoading(false);
        });

    }


    // if (couponCode === 'SUMMER20') {
    //   setAppliedCoupon(couponCode);
    //   setDiscountAmount(20); // Example: Applying a 20% discount
    //   setMessage('Coupon applied successfully.');
    //   setType('success');
    //   toggleCouponVisibility()
    // } else {
    //   setMessage('Invalid coupon code.');
    //   setType('error');
    // }
  };

  const removeCoupon = () => {
    setAppliedCoupon('');
    setDiscountAmount(0);
    setMessage('Coupon removed.');
    setType('success');
    setOpen(true);
    setCouponCode('')
    setCouponData(null)
    localStorage.removeItem('use_coupon')
  };

  // Handle Checkout Data
  const handleSubmit = async (event) => {
    event.preventDefault();

    const {
      fullName,
      lastName,
      email,
      phone,
      password,
      delivery_address,
      country,
      state,
      city,
      zip_code,
    } = formData;

    const newErrors = {};
    const phoneRegex = /^\+?(\d{1,3})?[-.\s●]?(\(?\d{1,4}\)?)?[-.\s●]?(\d{1,4})[-.\s●]?(\d{1,9})$/;

    if (totalItems === 0) {
      showMessage("Your cart is empty! Redirecting to products....", 'error');
      setTimeout(() => navigate('/category?type=all'), 2000);
      return;
    }

    if (!isLoggedIn) {
      validateGuestForm(newErrors, { fullName, lastName, email, phone, password, delivery_address, country, state, city, zip_code }, phoneRegex);
    } else if (!selectedAddress) {
      newErrors.fullName = "Please Select Address";
      showMessage(newErrors.fullName, 'error');
    }

    // if (paymentType === 'network_payment') {
    //   validateCreditCardForm(newErrors, formData);
    // }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setOrderLoading(true);
    try {
      const encryptedPassword = btoa(password);
      const checkoutData = prepareCheckoutData(isLoggedIn, {
        fullName,
        email,
        phone,
        password: encryptedPassword,
        delivery_address,
        country,
        state,
        city,
        zip_code,
        selectedAddress,
        items,
        couponData,
        paymentType,
      });

      // console.log('Checkout Data:', checkoutData); // Debugging line to check the data

      const response = await axios.post(`${BASE_URL}/api/v1/order/store`, checkoutData, {
        headers: isLoggedIn ? { Authorization: `Bearer ${localStorage.getItem('token')}` } : {},
      });

      handleOrderResponse(response);
    } catch (error) {
      console.error("Error:", error);
      setOrderLoading(false);
    }
  };

  const prepareCheckoutData = (isLoggedIn, formData) => {
    const { fullName, email, phone, delivery_address, password, country, state, city, zip_code, selectedAddress, items, couponData, paymentType } = formData;
    const commonData = {
      delivery_type: 'standard',
      payment_type: paymentType,
      coupon_id: couponData ? couponData.id : null,
      cart: items.map(item => ({ id: item.id, quantity: item.quantity })), // Ensure no circular references
    };

    if (isLoggedIn) {
      return { ...commonData, address_id: selectedAddress };
    } else {
      return {
        ...commonData,
        shipping_address_id: 1,
        billing_address_id: 1,
        transactionId: 1234,
        name: fullName,
        email,
        phone_number: phone,
        delivery_address,
        country_id: country,
        state_id: state,
        city_id: city,
        postal_code: zip_code,
        password,
      };
    }
  };


  const validateGuestForm = (newErrors, formData, phoneRegex) => {
    const { fullName, lastName, email, phone, password, delivery_address, country, state, city, zip_code } = formData;
    if (!fullName.trim()) newErrors.fullName = "Your First Name is required";
    if (!lastName.trim()) newErrors.lastName = "Your Last Name is required";
    if (!email.trim()) newErrors.email = "Email Address is required";
    else if (!/\S+@\S+\.\S+/.test(email)) newErrors.email = "Email Address is invalid";
    if (!password.trim()) newErrors.password = "Password is required";
    if (!phone.trim()) newErrors.phone = "Phone number is required";
    else if (!phoneRegex.test(phone)) newErrors.phone = "Phone number is invalid";
    if (!delivery_address.trim()) newErrors.delivery_address = "Delivery Address is required";
    if (!country) newErrors.country = "Country is required";
    if (!state) newErrors.state = "State is required";
    if (!city) newErrors.city = "City is required";
    if (!zip_code.trim()) newErrors.zip_code = "Zip Code is required";

    if (Object.keys(newErrors).length > 0) showMessage(Object.values(newErrors)[0], 'error');
  };

  const validateCreditCardForm = (newErrors, formData) => {
    const { cardCvv, cardExpirationDate, cardNumber, cardName } = formData;
    if (!cardCvv.trim() || cardCvv.length !== 3) {
      newErrors.cardCvv = "Please enter a valid 3-digit CVV";
      showMessage(newErrors.cardCvv, 'error');
    }
    if (!cardExpirationDate.trim() || !/^\d{2}\/\d{2}$/.test(cardExpirationDate)) {
      newErrors.cardExpirationDate = "Please enter a valid expiration date in MM/YY format";
      showMessage(newErrors.cardExpirationDate, 'error');
    } else {
      const [month, year] = cardExpirationDate.split('/').map((num) => parseInt(num, 10));
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1;
      const currentYear = currentDate.getFullYear() % 100;
      const isValidExpirationDate = (year > currentYear) || (year === currentYear && month >= currentMonth);
      if (!isValidExpirationDate) {
        newErrors.cardExpirationDate = "Expiration date must be after today";
        showMessage(newErrors.cardExpirationDate, 'error');
      }
    }
    if (!cardName.trim()) {
      newErrors.cardName = "Please enter the cardholder's name";
      showMessage(newErrors.cardName, 'error');
    }
    const cardNumberDigits = cardNumber.replace(/\s/g, '');
    if (!cardNumber.trim() || cardNumberDigits.length !== 16) {
      newErrors.cardNumber = "Please enter a valid 16-digit card number";
      showMessage(newErrors.cardNumber, 'error');
    }
  };


  const handleOrderResponse = (response) => {
    setOrderLoading(false);
    if (!response.data.success) {
      showMessage(response.data.message, 'error');
      return;
    }

   const aaa=  !isLoggedIn ? login(response.data.user, response.data.access_token, response.data.expires_at) : null

    if (response.data.payment_method === 'cash_on_delivery') {
      showMessage(response.data.message, 'success');
      emptyCart();
      removeCoupon();
      navigate('/thankyou?order_code=' + response.data.order_code);
    } else if (response.data.payment_method === 'network_payment') {
      emptyCart();
      removeCoupon();
      window.location.href = response.data.orderResponse['_links']['payment']['href'];
    }
  };

  const showMessage = (msg, type) => {
    setType(type);
    setMessage(msg);
    setOpen(true);
  };


  // Handle Checkout Data

  const Header = (
    <>
      <Box className="p-5" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box style={{ display: 'flex' }}>
          <p style={{ fontWeight: 900, fontSize: 'px' }}>Order Summary</p>
        </Box>
      </Box>
    </>
  )

  const Footer = (
    <Box className="p-5" style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Button
        variant='contained'
        className="poppins bg-[#2858a3] w-[100%]"
        style={{ height: "50px", textTransform: "capitalize" }}
        type="submit"
        onClick={handleSubmit}
        sx={{ mt: 3, mb: 2 }}
        disabled={orderLoading}
      >
        {orderLoading && <CircularProgress size={25} className="text-white" />}
        {!orderLoading && "Complete Order"}
      </Button>
    </Box>
  )

  const content = (
    <>
      <Box style={{ padding: '15px 15px 0px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <p className="text-[14px]" style={{ textOverflow: "ellipsis", overflow: "hidden", width: "126.5px", whiteSpace: "nowrap" }}> Sub Total </p>
        <p className="text-[14px]">AED {cartTotal.toFixed(2)}</p>
      </Box>

      <Box style={{ padding: '10px 15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <p className="text-[14px]" style={{ textOverflow: "ellipsis", overflow: "hidden", width: "126.5px", whiteSpace: "nowrap" }}> Shipping </p>
        <p className="text-[14px]">
          AED  {shippingCost ? shippingCost : 0.0}</p>
      </Box>
      <Divider />

      {
        discountAmount != 0 ?
          <>
            <Box style={{ padding: '10px 15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <p className="text-[14px]" style={{ textOverflow: "ellipsis", overflow: "hidden", width: "126.5px", whiteSpace: "nowrap" }}>Discount {appliedCoupon ? `(${appliedCoupon})` : ''}</p>
              <p className="text-[14px]">AED -{discountAmount}</p>
            </Box>
            <Divider />
          </>
          : null
      }

      <Box style={{ padding: '15px 15px 0px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <p className="text-[14px]" style={{ textOverflow: "ellipsis", overflow: "hidden", width: "126.5px", whiteSpace: "nowrap" }}>Order Total </p>
        <p className="text-[16px] font-[600]">
          {discountAmount !== 0
            ? `AED ${(parseFloat(cartTotal) - parseFloat(discountAmount) + parseFloat(shippingCost)).toFixed(2)}`
            : `AED ${(parseFloat(cartTotal) + parseFloat(shippingCost)).toFixed(2)}`}
        </p>

      </Box>
    </>
  )


  const CouponHtml = (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          background: "#fff",
          borderRadius: "4px",
          marginBottom: "10px",
          padding: "10px 20px", // Adjust padding as needed
          alignItems: "center",
        }}
        role="presentation"
      >

        {/* Apply Coupon text and toggle button */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flex: 1,
          }}
        >
          <p variant="h6" style={{ fontWeight: 900, fontSize: "16px" }}>
            {isCouponVisible ? 'Apply Coupon ' : 'Unlock Savings Today: Apply Your Exclusive Coupon!'}
          </p>
          <Button
            onClick={toggleCouponVisibility}
            variant="text"
            startIcon={isCouponVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          />
        </Box>

        {/* Coupon form */}
        <Box
          sx={{
            display: isCouponVisible ? "flex" : "none",
            flexDirection: "row",
            alignItems: "center",
            marginLeft: "auto", // Push form to the right
          }}
        >
          <TextField
            label="Coupon Code"
            variant="outlined"
            size="small"
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
            style={{ marginRight: "10px" }}
          />
          {!appliedCoupon ?
            <Button
              variant="contained"
              onClick={applyCoupon}
              className="poppins bg-[#2858a3] w-[80px]"
              style={{ height: "40px", textTransform: "capitalize" }}
              sx={{ mt: 1, mb: 1 }}
              disabled={couponLoading}
            >
              {couponLoading ? (
                <CircularProgress size={25} className="text-white" />
              ) : (
                "Apply"
              )}
            </Button>
            : <Button
              variant="contained"
              onClick={removeCoupon}
              className="poppins bg-[#2858a3] w-[80px]"
              style={{ height: "40px", textTransform: "capitalize" }}
              sx={{ mt: 1, mb: 1 }}
              disabled={couponLoading}
            >
              Remove
            </Button>
          }
        </Box>
      </Box>
    </>
  )

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          background: "#fff",
          borderRadius: '4px'
        }}
        role="presentation"
      >
        {CouponHtml}

        {Header}
        <Box sx={{ flex: '1 1 auto', overflow: 'auto', paddingX: 1 }}>
          {content}
        </Box>
        {Footer}
      </Box>
      {/* notification */}
      <NotificationBar
        open={open}
        setOpen={setOpen}
        type={type}
        message={message}
      />
    </>
  )
}

export default OrderSummary