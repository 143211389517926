import React from "react";
import { Box, Typography, Container, Paper } from "@mui/material";
import { Hero } from "../components";
import bg from "../assets/policy_banner.jpg";

const ShippingPolicy = () => {
  const shippingSections = [
    {
      title: "Shipping Locations",
      description: `Currently, Buraq Star offers shipping only within the following countries:\n
      - United Arab Emirates (UAE)
      - Pakistan
      - Bahrain
      - Qatar\n
      We do not offer international shipping outside of these countries. Orders placed from other locations will not be processed.`,
    },
    {
      title: "Delivery Process",
      description: `Once your order is successfully placed, we process it for local delivery within your country. Our delivery partners ensure secure and timely shipping.\n
      - **UAE:** Orders are delivered via local UAE delivery services.
      - **Pakistan:** Orders are handled by trusted domestic shipping partners.
      - **Bahrain:** Reliable local delivery services manage Bahrain shipments.
      - **Qatar:** Authorized local couriers fulfill all orders within Qatar.`,
    },
    {
      title: "Shipping Timeframes",
      description: `- Delivery times vary based on your location.
      - Estimated delivery time is **7 to 15 business days** depending on the delivery address and stock availability.
      - Delivery times are confirmed during checkout.`,
    },
    {
      title: "Shipping Charges",
      description: `Shipping charges are calculated at checkout based on your delivery location and order size/weight. We offer competitive shipping rates for maximum value.`,
    },
    {
      title: "Order Tracking",
      description: `Once your order is shipped, you will receive an email with tracking information. You can use the tracking number to monitor your order status.`,
    },
    {
      title: "Delivery Issues",
      description: `If you experience any delivery issues (e.g., delays, damage, or missing items), please contact our customer service team immediately. We will investigate and assist in resolving the issue promptly.`,
    },
    {
      title: "Return and Refund Policy",
      description: `If you are unsatisfied with your purchase or encounter a problem, please refer to our [Return and Refund Policy] for details.`,
    },
  ];

  return (
    <Box className="w-full bg-gray-50 min-h-screen">
      <Hero bg={bg} title="Shipping Policy" />
      <Container maxWidth="lg" className="py-10 px-4 sm:px-6 lg:px-10">
        <Paper elevation={3} className="p-6 sm:p-8 rounded-lg shadow-md bg-white">
          <Typography 
            variant="h4" 
            className="font-semibold text-gray-800 mb-4 text-center text-lg sm:text-2xl"
          >
            Shipping Policy
          </Typography>
          <Typography className="text-gray-700 leading-relaxed mb-6 text-sm sm:text-base">
            At Buraq Star, we are committed to providing a seamless and reliable shopping experience. Please read our shipping policy carefully.
          </Typography>
          {shippingSections.map((section, i) => (
            <Box key={i} className="mb-6">
              <Typography 
                variant="h6" 
                className="text-gray-800 font-bold mb-2 text-base sm:text-lg"
              >
                {i + 1}. {section.title}
              </Typography>
              <Typography className="text-gray-700 leading-relaxed text-sm sm:text-base whitespace-pre-line">
                {section.description}
              </Typography>
            </Box>
          ))}
        </Paper>
      </Container>
    </Box>
  );
};

export default ShippingPolicy;
