import React, { useContext, useState } from 'react';
import Sidebar from './Sidebar';
import AppHeader from './AppHeader';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../AuthContext';
import waterMark from './../../assets/watermark_panel.svg'; // Ensure the correct path and import
import ajaxService from '../../services/ajax-service';
import { Typography } from '@mui/material';

const DashboardLayout = ({ children, title }) => {
    const [isOpenSideBar, setIsOpenSideBar] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const { user, logout } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        try {
            await ajaxService.get('/auth/logout', 0, true);
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            localStorage.removeItem('expires_at');
            logout();
            navigate('/');
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    const handleProfile = () => {
        navigate('/user/profile');
    };

    return (
        // <div className="flex h-screen bg-gray-100">
        <div className="flex flex-col lg:flex-row h-screen bg-gray-100">

            <Sidebar user={user} isOpen={isOpenSideBar} handleLogout={handleLogout} setIsOpen={setIsOpenSideBar} />
            <div className="flex-grow flex flex-col">
                <AppHeader handleLogout={handleLogout} title={title} isOpen={isOpenSideBar} setIsOpen={setIsOpenSideBar} />
                <div className="relative flex-grow p-6 h-full overflow-y-auto">
                    {/* Watermark */}
                    <div
                        className={`transition-all duration-300 fixed inset-6 sm:mt- sm:inset-20 ${isOpenSideBar ? 'sm:left-96': 'sm:left-44'} `}
                        style={{
                            backgroundImage: `url(${waterMark})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'contain',
                            opacity: 0.8, 
                            zIndex: 0, // Ensure watermark stays behind content
                            pointerEvents: 'none', // Optional: allows clicks to pass through
                        }}
                    >
                    </div>

                    {/* Content */}
                    <div className="relative z-10">
                        {children}
                    </div>


                </div>
                <footer className="text-center sticky bottom-0 ">
                    <Typography variant="caption" color="textSecondary">
                        © {new Date().getFullYear()} BuraqStar
                    </Typography>
                </footer>
            </div>
        </div>
    );
};

export default DashboardLayout;


