import React, { useState } from "react";
import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Grid,
  Card,
  CardContent,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
  Divider,
  Box,
} from "@mui/material";
import { CloudUpload } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ajaxService from "../../services/ajax-service";

const RetailerRegistration = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState({
    business_name: "",
    business_type: "",
    other_business_type: "",
    registration_number: "",
    country: "",
    city: "",
    address: "",
    website: "",
    contact_person_name: "",
    position: "",
    email: "",
    phone: "",
    products: "",
    target_market: "",
    years_in_business: "",
    tax_number: "",
    terms_accepted: false,
    documents: {
      business_certificate: null,
      tax_certificate: null,
      other_documents: null,
      trading_license_certificate: null,
      chamber_of_commerce_certificate: null,
      sponsor_passport_copy: null,
      sponsor_eid_copy: null,
      manager_passport_copy: null,
      manager_visa_copy: null,
      manager_eid_copy: null,
      tenancy_contract_copy: null,
    },
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    setFormData({
      ...formData,
      documents: {
        ...formData.documents,
        [name]: files[0],
      },
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const newErrors = {};

    // Validation
    if (!formData.business_name.trim()) newErrors.business_name = "Business Name is required.";
    if (!formData.business_type.trim()) {
      newErrors.business_type = "Business Type is required.";
    } else if (formData.business_type === "Other" && !formData.other_business_type.trim()) {
      newErrors.other_business_type = "Please specify your business type.";
    }
    if (!formData.registration_number.trim())
      newErrors.registration_number = "Registration Number is required.";
    if (!formData.country.trim()) newErrors.country = "Country is required.";
    if (!formData.city.trim()) newErrors.city = "City is required.";
    if (!formData.address.trim()) newErrors.address = "Address is required.";
    if (!formData.contact_person_name.trim())
      newErrors.contact_person_name = "Contact Person Name is required.";
    if (!formData.position.trim()) newErrors.position = "Position is required.";
    if (!formData.email.trim()) newErrors.email = "Email is required.";
    else if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = "Invalid email format.";
    if (!formData.phone.trim()) newErrors.phone = "Phone Number is required.";
    if (!formData.products.trim()) newErrors.products = "Products/Services Offered is required.";
    if (!formData.years_in_business.trim()) newErrors.years_in_business = "Years in Business is required.";
    if (!formData.terms_accepted)
      newErrors.terms_accepted = "You must accept the terms and conditions.";
    if (!formData.documents.business_certificate)
      newErrors.business_certificate = "Business Registration Certificate is required.";
    if (!formData.documents.trading_license_certificate)
      newErrors.trading_license_certificate = "Trading License Certificate is required.";
    if (!formData.documents.chamber_of_commerce_certificate)
      newErrors.chamber_of_commerce_certificate = "Chamber of Commerce Certificate is required.";
    if (!formData.documents.sponsor_passport_copy)
      newErrors.sponsor_passport_copy = "Sponsor Passport Copy is required.";
    if (!formData.documents.sponsor_eid_copy)
      newErrors.sponsor_eid_copy = "Sponsor EID Copy is required.";
    if (!formData.documents.manager_passport_copy)
      newErrors.manager_passport_copy = "Manager Passport Copy is required.";
    if (!formData.documents.manager_visa_copy)
      newErrors.manager_visa_copy = "Manager Visa Copy is required.";
    if (!formData.documents.manager_eid_copy)
      newErrors.manager_eid_copy = "Manager EID Copy is required.";
    if (!formData.documents.tenancy_contract_copy)
      newErrors.tenancy_contract_copy = "Tenancy Contract Copy is required.";


    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // Form Submission Logic
    setLoading(true);
    const formDataToSend = new FormData();
    Object.keys(formData).forEach((key) => {
      if (key === "documents") {
        Object.keys(formData.documents).forEach((docKey) => {
          if (formData.documents[docKey]) {
            formDataToSend.append(docKey, formData.documents[docKey]);
          }
        });
      } else {
        formDataToSend.append(key, formData[key]);
      }
    });
    if (formData.business_type === "Other") {
      formDataToSend.append("business_type", formData.other_business_type);
    }

    try {
      const response = await ajaxService.post("/retailer", formDataToSend);
      setLoading(false);

      if (!response.success) {
        alert(response.message);
      } else {
        setSuccess(true)
      }
    } catch (error) {
      console.error("Error during registration:", error);
      setLoading(false);
      alert("An error occurred during registration. Please try again later.");
    }
  };

  return (
    !success ?
      <Grid container justifyContent="center" sx={{ p: 3 }} >
        <Card sx={{ maxWidth: 900, width: "100%", borderRadius: 2, boxShadow: 4 }}>
          <CardContent>
            <Typography component="h1" variant="h6" style={{ color: '#b5b5bf' }}>
              WELCOME TO
            </Typography>
            <Typography component="h1" variant="h4" className='poppins' style={{ color: '#2858a3', fontWeight: 900 }}>
              BURAQ STAR
            </Typography>

            <Typography variant="h4" color="primary" className='poppins ' style={{ color: '#2858a3', fontWeight: 900 }} gutterBottom>
              Retailer Registration Form
            </Typography>
            <Divider sx={{ my: 3 }} />

            <form onSubmit={handleSubmit}>
              {/* Section 1: Business Information */}
              <Typography variant="h6" color="primary" className='poppins ' style={{ color: '#2858a3', fontWeight: 900 }} gutterBottom>
                Business Information
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Business Name"
                    name="business_name"
                    placeholder="Enter your business name"
                    value={formData.business_name}
                    onChange={handleInputChange}
                    error={!!errors.business_name}
                    helperText={errors.business_name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth error={!!errors.business_type}>
                    <InputLabel>Business Type</InputLabel>
                    <Select
                      name="business_type"
                      value={formData.business_type}
                      onChange={(e) => {
                        const { value } = e.target;
                        setFormData({
                          ...formData,
                          business_type: value,
                        });
                        setErrors({
                          ...errors,
                          business_type: "",
                        });
                      }}
                    >
                      <MenuItem value="Manufacturer">Manufacturer</MenuItem>
                      <MenuItem value="Wholesaler">Wholesaler</MenuItem>
                      <MenuItem value="Distributor">Distributor</MenuItem>
                      <MenuItem value="Retailer">Retailer</MenuItem>
                      <MenuItem value="Other">Other (specify)</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {/* If "Other" is selected, render a text field */}
                {formData.business_type === "Other" && (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Specify Business Type"
                      name="other_business_type"
                      placeholder="Enter your business type"
                      value={formData.other_business_type || ""}
                      onChange={(e) => {
                        const { value } = e.target;
                        setFormData({
                          ...formData,
                          other_business_type: value,
                        });
                        setErrors({
                          ...errors,
                          other_business_type: "",
                        });
                      }}
                      error={!!errors.other_business_type}
                      helperText={errors.other_business_type}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Business Registration Number"
                    name="registration_number"
                    placeholder="Enter registration number or Trade License No"
                    value={formData.registration_number}
                    onChange={handleInputChange}
                    error={!!errors.registration_number}
                    helperText={errors.registration_number}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Country"
                    name="country"
                    placeholder="Select your country"
                    value={formData.country}
                    onChange={handleInputChange}
                    error={!!errors.country}
                    helperText={errors.country}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="City"
                    name="city"
                    placeholder="Enter your city"
                    value={formData.city}
                    onChange={handleInputChange}
                    error={!!errors.city}
                    helperText={errors.city}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Address"
                    name="address"
                    placeholder="Enter your address"
                    multiline
                    rows={3}
                    value={formData.address}
                    onChange={handleInputChange}
                    error={!!errors.address}
                    helperText={errors.address}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Business Website or Social Media Links"
                    name="website"
                    placeholder="Enter website or social media links"
                    value={formData.website}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>

              {/* Section 2: Contact Information */}
              <Typography variant="h6" color="primary" className='poppins ' style={{ color: '#2858a3', fontWeight: 900 }} gutterBottom sx={{ mt: 4 }}>
                Contact Information
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Contact Person Name"
                    name="contact_person_name"
                    value={formData.contact_person_name}
                    onChange={handleInputChange}
                    error={!!errors.contact_person_name}
                    helperText={errors.contact_person_name}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Position/Title"
                    name="position"
                    value={formData.position}
                    onChange={handleInputChange}
                    error={!!errors.position}
                    helperText={errors.position}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Phone Number"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    error={!!errors.phone}
                    helperText={errors.phone}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Email Address"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    error={!!errors.email}
                    helperText={errors.email}
                  />
                </Grid>
              </Grid>

              {/* Section 3: Business Details */}
              <Typography variant="h6" color="primary" className='poppins ' style={{ color: '#2858a3', fontWeight: 900 }} gutterBottom sx={{ mt: 4 }}>
                Business Details
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Products/Services Offered"
                    name="products"
                    placeholder="List your products or services"
                    multiline
                    rows={3}
                    value={formData.products}
                    onChange={handleInputChange}
                    error={!!errors.products}
                    helperText={errors.products}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel>Target Market</InputLabel>
                    <Select
                      name="target_market"
                      value={formData.target_market}
                      onChange={handleInputChange}
                    >
                      <MenuItem value="B2B">B2B</MenuItem>
                      <MenuItem value="B2C">B2C</MenuItem>
                      <MenuItem value="Both">Both</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Years in Business"
                    name="years_in_business"
                    type="number"
                    value={formData.years_in_business}
                    onChange={handleInputChange}
                    error={!!errors.years_in_business}
                    helperText={errors.years_in_business}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Tax Registration Number (Optional)"
                    name="tax_number"
                    value={formData.tax_number}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>

              {/* Section 4: Upload Documents */}
              <Typography variant="h6" color="primary" className='poppins ' style={{ color: '#2858a3', fontWeight: 900 }} gutterBottom sx={{ mt: 4 }}>
                Upload Documents
              </Typography>

              <Box sx={{ my: 2, ml: 2 }}>
                <ul style={{ listStyle: "disc" }}>

                  <li>
                    <Typography variant="body1" color="textPrimary">
                      Business Registration Certificate (Mandatory)
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body1" color="textPrimary">
                      Trading License Certificate (Mandatory)
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body1" color="textPrimary">
                      Chamber of Commerce Certificate (Mandatory)
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body1" color="textPrimary">
                      Sponsor Passport Copy (Mandatory)
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body1" color="textPrimary">
                      Sponsor EID Copy (Mandatory)
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body1" color="textPrimary">
                      Manager Passport Copy (Mandatory)
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body1" color="textPrimary">
                      Manager Visa Copy (Mandatory)
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body1" color="textPrimary">
                      Manager EID Copy (Mandatory)
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body1" color="textPrimary">
                      Tenancy Contract Copy (Mandatory)
                    </Typography>
                  </li>
                </ul>
              </Box>

              <Box sx={{ my: 2 }}>
                <Button
                  variant="outlined"
                  fullWidth
                  startIcon={<CloudUpload />}
                  component="label"
                  sx={{ mb: 2 }}
                >
                  Upload Business Registration Certificate
                  <input
                    type="file"
                    name="business_certificate"
                    hidden
                    onChange={handleFileChange}
                  />
                </Button>
                {errors.business_certificate && (
                  <Typography color="error">{errors.business_certificate}</Typography>
                )}

                <Button
                  variant="outlined"
                  fullWidth
                  startIcon={<CloudUpload />}
                  component="label"
                  sx={{ mb: 2 }}
                >
                  Upload Trading License Certificate
                  <input
                    type="file"
                    name="trading_license_certificate"
                    hidden
                    onChange={handleFileChange}
                  />
                </Button>
                {errors.trading_license_certificate && (
                  <Typography color="error">{errors.trading_license_certificate}</Typography>
                )}

                <Button
                  variant="outlined"
                  fullWidth
                  startIcon={<CloudUpload />}
                  component="label"
                  sx={{ mb: 2 }}
                >
                  Upload Chamber of Commerce Certificate
                  <input
                    type="file"
                    name="chamber_of_commerce_certificate"
                    hidden
                    onChange={handleFileChange}
                  />
                </Button>
                {errors.chamber_of_commerce_certificate && (
                  <Typography color="error">{errors.chamber_of_commerce_certificate}</Typography>
                )}

                <Button
                  variant="outlined"
                  fullWidth
                  startIcon={<CloudUpload />}
                  component="label"
                  sx={{ mb: 2 }}
                >
                  Upload Sponsor Passport Copy
                  <input
                    type="file"
                    name="sponsor_passport_copy"
                    hidden
                    onChange={handleFileChange}
                  />
                </Button>
                {errors.sponsor_passport_copy && (
                  <Typography color="error">{errors.sponsor_passport_copy}</Typography>
                )}

                <Button
                  variant="outlined"
                  fullWidth
                  startIcon={<CloudUpload />}
                  component="label"
                  sx={{ mb: 2 }}
                >
                  Upload Sponsor EID Copy
                  <input
                    type="file"
                    name="sponsor_eid_copy"
                    hidden
                    onChange={handleFileChange}
                  />
                </Button>
                {errors.sponsor_eid_copy && (
                  <Typography color="error">{errors.sponsor_eid_copy}</Typography>
                )}

                <Button
                  variant="outlined"
                  fullWidth
                  startIcon={<CloudUpload />}
                  component="label"
                  sx={{ mb: 2 }}
                >
                  Upload Manager Passport Copy
                  <input
                    type="file"
                    name="manager_passport_copy"
                    hidden
                    onChange={handleFileChange}
                  />
                </Button>
                {errors.manager_passport_copy && (
                  <Typography color="error">{errors.manager_passport_copy}</Typography>
                )}

                <Button
                  variant="outlined"
                  fullWidth
                  startIcon={<CloudUpload />}
                  component="label"
                  sx={{ mb: 2 }}
                >
                  Upload Manager Visa Copy
                  <input
                    type="file"
                    name="manager_visa_copy"
                    hidden
                    onChange={handleFileChange}
                  />
                </Button>
                {errors.manager_visa_copy && (
                  <Typography color="error">{errors.manager_visa_copy}</Typography>
                )}

                <Button
                  variant="outlined"
                  fullWidth
                  startIcon={<CloudUpload />}
                  component="label"
                  sx={{ mb: 2 }}
                >
                  Upload Manager EID Copy
                  <input
                    type="file"
                    name="manager_eid_copy"
                    hidden
                    onChange={handleFileChange}
                  />
                </Button>
                {errors.manager_eid_copy && (
                  <Typography color="error">{errors.manager_eid_copy}</Typography>
                )}

                <Button
                  variant="outlined"
                  fullWidth
                  startIcon={<CloudUpload />}
                  component="label"
                  sx={{ mb: 2 }}
                >
                  Upload Tenancy Contract Copy
                  <input
                    type="file"
                    name="tenancy_contract_copy"
                    hidden
                    onChange={handleFileChange}
                  />
                </Button>
                {errors.tenancy_contract_copy && (
                  <Typography color="error">{errors.tenancy_contract_copy}</Typography>
                )}


                <Button
                  variant="outlined"
                  fullWidth
                  startIcon={<CloudUpload />}
                  component="label"
                  sx={{ mb: 2 }}
                >
                  Upload Tax Certification Document (Optional)
                  <input
                    type="file"
                    name="tax_certificate"
                    hidden
                    onChange={handleFileChange}
                  />
                </Button>

              </Box>

              {/* Section 5: Agreement & Submission */}
              <FormControlLabel
                control={
                  <Checkbox
                    name="terms_accepted"
                    className="poppins"
                    checked={formData.terms_accepted}
                    onChange={(e) =>
                      setFormData({ ...formData, terms_accepted: e.target.checked })
                    }
                  />
                }
                label={
                  <Typography variant="body2" sx={{ display: "inline" }}>
                    I agree to the{" "}
                    <a
                      href="/terms"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "#2858a3", textDecoration: "underline" }}
                    >
                      terms and conditions
                    </a>.
                  </Typography>
                }
              />

              {errors.terms_accepted && (
                <Typography color="error">{errors.terms_accepted}</Typography>
              )}
              <Button
                fullWidth
                className='poppins bg-[#2858a3] '
                style={{ height: '50px', textTransform: 'capitalize' }}
                variant="contained"
                color="primary"
                sx={{ mt: 3 }}
                type="submit"
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : "Register Now"}
              </Button>
            </form>
          </CardContent>
        </Card>
      </Grid>
      : <ThankYou />
  );
};

export default RetailerRegistration;

export const ThankYou = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "#f9f9f9",
      }}
    >
      <Typography variant="h4" sx={{ mb: 2, color: "#2858a3", fontWeight: "bold" }}>
        🎉 Thank You for Registering!
      </Typography>
      <Typography variant="body1" sx={{ mb: 4, textAlign: "center", maxWidth: 500 }}>
        Your registration has been successfully submitted. Our team will review your
        application, and you will receive an email upon approval. For any further
        queries, feel free to contact us.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate("/")}
        sx={{ textTransform: "capitalize" }}
      >
        Back to Home
      </Button>
    </Box>
  );
};
