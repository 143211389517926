// AuthContext.js
import React, { createContext, useState } from 'react';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));
    const [user, setUser] = useState(() => {
        const userString = localStorage.getItem('user');
        return userString ? JSON.parse(userString) : null;
        // return  null;
    });

    const [currency, setCurrency] = useState(() => {
        const storedCurrency = localStorage.getItem('currency');
        if (storedCurrency) {
            return storedCurrency;
        } else {
            const defaultCurrency = 'AED';
            localStorage.setItem('currency', defaultCurrency);
            return defaultCurrency;
        }
    });

    const updateCurrency = (newCurrency) => {
        setCurrency(newCurrency);
        localStorage.setItem('currency', newCurrency);
    };

    const login = (userData, token, expires_at) => {
        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('token', token);
        localStorage.setItem('expires_at', expires_at);
        setIsLoggedIn(true);
        setUser(userData);
    };

    const logout = () => {
        setIsLoggedIn(false);
        setUser(null);
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('expires_at');
    };

    return (
        <AuthContext.Provider value={{ isLoggedIn, user, login, logout, setUser, currency, updateCurrency }}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthContext, AuthProvider };
