// Blogs.jsx

import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import bg from "../assets/blog-hero.png";
import ajaxService from "../services/ajax-service";
import { BrandBanner, Hero, Slider, SliderTwo } from "../components";
import SkeletonBlog from "../skeleton/SkeletonBlog"; // Import SkeletonBlog component

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true); // State to track loading state

  const initialize = async () => {
    const response = await ajaxService.get('/blogs');
    const { data, success } = response;
    if (success) {
      setBlogs(data);
    }
    setLoading(false); // Once data is fetched, set loading state to false
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <Box className="w-full h-auto m-0 p-0">
      <Hero bg={bg} title={"Blogs"} />

      {/* Show SkeletonBlog component while loading */}
      {loading ? (
       <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
       {[...Array(6)].map((_, index) => (
         <div key={index} className="col-span-1">
           <SkeletonBlog />
         </div>
       ))}
     </div>
      ) : (
        <>
          {/* Render actual content once loading is complete */}
          <Slider blogs={blogs} />
          <SliderTwo blogs={blogs} />
          <Box className="mb-20">
            <BrandBanner />
          </Box>
        </>
      )}
    </Box>
  );
};

export default Blogs;
